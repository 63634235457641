<template>
  <v-container>
    <v-row class="flex-row justify-center">
      <h1 class="py-10 my-10 px-2 mx-2 text-center text-primary text-bold">
        Page Not Found
      </h1>
    </v-row>
    <v-row class="flex-row justify-center">
      <p class="px-2 mx-2 text-center text-secondary">
        If you believe this is a mistake. Please go to
        <a href="https://discord.gg/cynduqjv">our discord</a> then go to
        #bug-report channel. Once there submit the issue that you have and an
        engineer will create a ticket
      </p>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class PageNotFoundView extends Vue {}
</script>
