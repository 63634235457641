import "reflect-metadata";
import { inject, injectable } from "inversify-props";
import ICampaignListViewModel from "./ICampaignListViewModel";
import ICampaignService from "@/core/services/CampaignService/ICampaignService";
import ICampaign from "@/core/models/Campaign/ICampaign";
import ILanguageService from "@/core/services/LanguageService/ILanguageService";
import Session from "@/store/Session";
import { AllTranslationKeys } from "@/core/constants/AllTranslationKeys";

@injectable()
export default class CampaignListViewModel implements ICampaignListViewModel {
  @inject("CampaignService")
  private readonly _campaignService!: ICampaignService;

  @inject("LanguageService")
  private readonly _languageService!: ILanguageService;

  private _myCampaigns: Array<ICampaign> = [];
  private _myCampPage = 0;
  private _myCampLimit = 2;

  public get NoCampaigns(): string {
    return this._languageService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.NO_CAMPAIGNS);
  }

  public get TryAgainLabel(): string {
    return this._languageService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.TRY_AGAIN);
  }

  public get MyCampaigns(): Array<ICampaign> {
    return this._myCampaigns;
  }

  public PageDown(): void {
    this._myCampPage += 1;
  }

  public async FetchMyCampaigns(): Promise<void> {
    const serviceResult = await this._campaignService.FetchMyCampaignsAsync(
      this._myCampPage,
      this._myCampLimit
    );
    this._myCampPage =
      serviceResult?.length < 1 ? this._myCampPage - 1 : this._myCampPage;
    this._myCampaigns = this._myCampaigns?.concat(serviceResult);
  }
}
