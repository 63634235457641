import "reflect-metadata";
import { inject, injectable } from "inversify-props";
import Phaser from "phaser";
import ILoadingSceneViewModel from "@/core/viewModels/LoadingSceneViewModel/ILoadingSceneViewModel";

@injectable()
export default class LoadingScene extends Phaser.Scene {
  private readonly _viewModel!: ILoadingSceneViewModel;

  public constructor(
    @inject("LoadingSceneViewModel") viewModel: ILoadingSceneViewModel
  ) {
    super({ key: "LoadingScene" });
    this._viewModel = viewModel;
  }

  public preload() {
    this._viewModel.CreateProgressBarAndBox(this);
    this._viewModel.ProcessLoading(this);
  }

  public create() {
    this._viewModel.CreateLogo(this);
  }
}
