import { container } from "inversify-props";
import LoadingScene from "../game/scenes/LoadingScene";
import IPhaserCustomConfig from "../models/Campaign/IPhaserCustomConfig";
import AwaitLoaderPlugin from "phaser3-rex-plugins/plugins/awaitloader-plugin.js";
import Gestures from "phaser3-rex-plugins/plugins/gestures";
import MapScene from "../game/scenes/MapScene";

export default function (parent: string): IPhaserCustomConfig {
  return {
    type: Phaser.AUTO,
    width: window.innerWidth,
    height: window.innerHeight,
    parent: parent || "game-container",
    backgroundColor: "#353839",
    scene: [container.resolve(LoadingScene), container.resolve(MapScene)],
    plugins: {
      global: [
        {
          key: "rexAwaitLoader",
          plugin: AwaitLoaderPlugin,
          start: true,
        },
      ],
    },
  };
}
