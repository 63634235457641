// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify, ThemeDefinition, VuetifyOptions } from "vuetify";

const lightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: "#A5CBF4", // Oxford Blue
    secondary: "#708090", // Slate Gray
    accent: "#C0392B", // Crimson Red
    background: "#F8F8F8", // Off-White
    surface: "#FFFFFF", // White
  },
};

const darkTheme: ThemeDefinition = {
  dark: true,
  colors: {
    primary: "#002447", // Dark Olive Green
    secondary: "#2C3E50", // Gunmetal Gray
    accent: "#C0392B", // Crimson Red
    background: "#2C2C2C", // Charcoal
    surface: "#ECECEC", // Light Gray
  },
};

export default createVuetify({
  theme: {
    defaultTheme: "lightTheme",
    themes: {
      lightTheme,
      darkTheme,
    },
    options: {
      customProperties: true,
    },
  },
} as VuetifyOptions);
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
