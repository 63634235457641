import { injectable } from "inversify-props";
import "reflect-metadata";
import IExitBuildButtonCommand from "./IExitBuildButtonCommand";
import { Scene, GameObjects } from "phaser";
import MapScene from "@/core/game/scenes/MapScene";

@injectable()
export default class ExitBuildButtonCommand implements IExitBuildButtonCommand {
  Execute<T extends Scene>(
    scene: T,
    parentContainer: GameObjects.Container
  ): GameObjects.Container {
    const exitBuildButtonContainer = scene.add.container(
      parentContainer.x + 10,
      parentContainer.y + 5
    );
    scene.events.on("postupdate", () => {
      exitBuildButtonContainer.setPosition(
        scene.cameras.main.scrollX + parentContainer.x + 15,
        scene.cameras.main.scrollY + parentContainer.y + 10
      );
    });
    const backRect = scene.add.rectangle(0, 0, 320, 50, 0xececec, 0.75);
    backRect.setOrigin(0, 0);
    backRect.setInteractive();
    backRect.on("pointerup", () => {
      (scene as Scene as MapScene).VM.IsBuildMenu = false;
      parentContainer.destroy();
    });
    exitBuildButtonContainer.add(backRect);
    const backText = scene.add.text(15, 5, "Exit", {
      fontSize: "30px",
      fontFamily: "Arial",
      color: "#000000",
    });
    exitBuildButtonContainer.setDepth(100);
    exitBuildButtonContainer.add(backText);
    parentContainer.add(exitBuildButtonContainer);
    return exitBuildButtonContainer;
  }
}
