import { injectable } from "inversify-props";
import "reflect-metadata";
import IExitButtonCommand from "./IExitButtonCommand";
import Phaser, { Scene, GameObjects } from "phaser";
import MapScene from "@/core/game/scenes/MapScene";

@injectable()
export default class ExitButtonCommand implements IExitButtonCommand {
  public Execute<T extends Scene>(
    scene: T,
    parentContainer: GameObjects.Container
  ): GameObjects.Container {
    const buttonContainer = scene.add.container(132, 60);
    const shadow = scene.add.graphics();
    shadow.fillStyle(0x000000, 0.5);
    shadow.fillRoundedRect(5, 5, 103, 45, 10);
    const buttonRect = scene.add.graphics();
    buttonRect.fillStyle(0xc0392b, 1);
    buttonRect.fillRoundedRect(0, 0, 103, 45, 10);
    buttonRect.setInteractive(
      new Phaser.Geom.Rectangle(0, 0, 103, 45),
      Phaser.Geom.Rectangle.Contains
    );
    buttonRect.on("pointerup", () => {
      (scene as Scene as MapScene).VM.IsHexMenu = false;
      parentContainer.destroy();
    });
    buttonContainer.add(shadow);
    buttonContainer.add(buttonRect);
    const exitText = scene.add.text(20, 10, "EXIT", {
      fontSize: "25px",
      fontFamily: "Arial",
      color: "#000000",
    });
    buttonContainer.setDepth(100);
    buttonContainer.add(exitText);
    parentContainer.add(buttonContainer);
    return buttonContainer;
  }
}
