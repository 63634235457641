import { injectable, inject } from "inversify-props";
import "reflect-metadata";
import ISceneEventCommandHandler from "./ISceneEventCommandHandler";
import { Scene } from "phaser";
import ISceneEventCommand from "../SceneEventCommands/ISceneEventCommand";
import IDraggableCameraEventCommand from "../SceneEventCommands/IDraggableCameraEventCommand";
import IPinchEventCommand from "../SceneEventCommands/IPinchEventCommand";
import ITouchAndHoldEventCommand from "../SceneEventCommands/ITouchAndHoldEventCommand";

@injectable()
export default class SceneEventCommandHandler
  implements ISceneEventCommandHandler
{
  private readonly _commandMap: { [key: string]: ISceneEventCommand };

  public constructor(
    @inject("DraggableCameraEventCommand")
    dragStart: IDraggableCameraEventCommand,
    @inject("PinchEventCommand")
    pinch: IPinchEventCommand,
    @inject("TouchAndHoldEventCommand")
    touchAndHold: ITouchAndHoldEventCommand
  ) {
    this._commandMap = {
      DRAG: dragStart,
      PINCH: pinch,
      TOUCH_AND_HOLD: touchAndHold,
    };
  }

  public ApplyEvent(commandName: string, scene: Scene): void {
    const command = this._commandMap[commandName];
    if (command) {
      command.Execute(scene);
    }
  }
}
