import { inject, injectable } from "inversify-props";
import "reflect-metadata";
import IShowGridButtongCommand from "./IShowGridButtonCommand";
import { Scene, GameObjects } from "phaser";
import MapScene from "@/core/game/scenes/MapScene";
import IHexGridBuilder from "@/core/builders/HexGridBuilder/IHexGridBuilder";

@injectable()
export default class ShowGridButtonCommand implements IShowGridButtongCommand {
  private readonly hexBuilder: IHexGridBuilder;

  public constructor(@inject("HexGridBuilder") hexBuilder: IHexGridBuilder) {
    this.hexBuilder = hexBuilder;
  }

  public Execute<T extends Scene>(
    scene: T,
    parentContainer: GameObjects.Container
  ): GameObjects.Container {
    const showGridContainer = scene.add.container(
      parentContainer.x + 10,
      parentContainer.y + 175
    );
    scene.events.on("postupdate", () => {
      showGridContainer.setPosition(
        scene.cameras.main.scrollX + parentContainer.x + 25,
        scene.cameras.main.scrollY + parentContainer.y + 175
      );
    });
    const backRect = scene.add.rectangle(0, 0, 320, 50, 0xececec, 0.75);
    backRect.setOrigin(0, 0);
    backRect.setInteractive();
    backRect.on("pointerup", () => {
      if ((scene as Scene as MapScene).VM.IsShowGrid) {
        (scene as Scene as MapScene).VM.IsShowGrid = false;
        (scene as Scene as MapScene).Hexes = this.hexBuilder.Build(scene);
      } else {
        (scene as Scene as MapScene).VM.IsShowGrid = true;
        (scene as Scene as MapScene).Hexes = this.hexBuilder.Build(scene);
      }
    });
    showGridContainer.add(backRect);
    const backText = scene.add.text(55, 5, "Toggle Grid", {
      fontSize: "30px",
      fontFamily: "Arial",
      color: "#000000",
    });
    showGridContainer.setDepth(100);
    showGridContainer.add(backText);
    parentContainer.add(showGridContainer);
    return showGridContainer;
  }
}
