<template>
  <v-container>
    <v-row>
      <v-col>
        <v-row>
          <v-col cols="12">
            <v-btn rounded disabled block>Login With Facebook</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn rounded disabled block>Login With Google</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import { Vue } from "vue-class-component";

export default class AuxLoginView extends Vue {}
</script>
