import { injectable } from "inversify-props";
import "reflect-metadata";
import IHistoryButtonCommand from "./IHistoryButtonCommand";
import Phaser, { Scene, GameObjects } from "phaser";
import MapScene from "@/core/game/scenes/MapScene";

@injectable()
export default class HistoryButtonCommand implements IHistoryButtonCommand {
  public Execute<T extends Scene>(
    scene: T,
    parentContainer: GameObjects.Container
  ): GameObjects.Container {
    const historyButtonContainer = scene.add.container(132, 125);
    const shadow = scene.add.graphics();
    shadow.fillStyle(0x000000, 0.5);
    shadow.fillRoundedRect(5, 5, 103, 45, 10);
    const historyRect = scene.add.graphics();
    historyRect.fillStyle(0xa5cbf4, 1);
    historyRect.fillRoundedRect(0, 0, 103, 45, 10);
    historyRect.setInteractive(
      new Phaser.Geom.Rectangle(0, 0, 103, 45),
      Phaser.Geom.Rectangle.Contains
    );
    historyRect.on("pointerup", () => {
      (scene as Scene as MapScene).VM.HandleHistoryClick(
        parentContainer.getData("hex")._id
      );
    });
    const historyText = scene.add.text(5, 10, "History", {
      fontSize: "25px",
      fontFamily: "Arial",
      color: "#000000",
    });
    historyButtonContainer.add(shadow);
    historyButtonContainer.add(historyRect);
    historyButtonContainer.add(historyText);
    historyButtonContainer.setDepth(100);
    parentContainer.add(historyButtonContainer);
    return historyButtonContainer;
  }
}
