import { inject, injectable } from "inversify-props";
import "reflect-metadata";
import IButtonCommandHandler from "./IButtonCommandHandler";
import IButtonCommand from "../ButtonCommands/IButtonCommand";
import { GameObjects, Scene } from "phaser";
import IBackToMainButtonCommand from "../ButtonCommands/IBackToMainButtonCommand";
import IShowGridButtonCommand from "../ButtonCommands/IShowGridButtonCommand";
import IShowColorsButtonCommand from "../ButtonCommands/IShowColorsButtonCommand";
import IExitButtonCommand from "../ButtonCommands/IExitButtonCommand";
import IAttackButtonCommand from "../ButtonCommands/IAttackButtonCommand";
import IBuildButtonCommand from "../ButtonCommands/IBuildButtonCommand";
import IHistoryButtonCommand from "../ButtonCommands/IHistoryButtonCommand";
import IExitBuildButtonCommand from "../ButtonCommands/IExitBuildButtonCommand";
import IBuildHideoutButtonCommand from "../ButtonCommands/IBuildHideoutButtonCommand";
import IBuildArmoryButtonCommand from "../ButtonCommands/IBuildArmoryButtonCommand";
import IBuildWatchTowerButtonCommand from "../ButtonCommands/IBuildWatchTowerButtonCommand";
import IBuildArcaneNodeButtonCommand from "../ButtonCommands/IBuildArcaneNodeButtonCommand";
import IBuildHealingShrineButtonCommand from "../ButtonCommands/IBuildHealingShrineButtonCommand";
import IBuildTrainingGroundButtonCommand from "../ButtonCommands/IBuildTrainingGroundButtonCommand";

@injectable()
export default class ButtonCommandHandler implements IButtonCommandHandler {
  private readonly _buttonMap: { [key: string]: IButtonCommand };

  public constructor(
    @inject("BackToMainButtonCommand")
    backToMainButtonCommand: IBackToMainButtonCommand,
    @inject("ShowGridButtonCommand")
    showGridButtonCommand: IShowGridButtonCommand,
    @inject("ShowColorsButtonCommand")
    showColorsButtonCommand: IShowColorsButtonCommand,
    @inject("ExitButtonCommand")
    exitButtonCommand: IExitButtonCommand,
    @inject("AttackButtonCommand")
    attackButtonCommand: IAttackButtonCommand,
    @inject("BuildButtonCommand")
    buildButtonCommand: IBuildButtonCommand,
    @inject("HistoryButtonCommand")
    historyCommand: IHistoryButtonCommand,
    @inject("ExitBuildButtonCommand")
    exitBuildButtonCommand: IExitBuildButtonCommand,
    @inject("BuildHideoutButtonCommand")
    buildHideout: IBuildHideoutButtonCommand,
    @inject("BuildArmoryButtonCommand")
    buildArmory: IBuildArmoryButtonCommand,
    @inject("BuildWatchTowerButtonCommand")
    buildWatchTower: IBuildWatchTowerButtonCommand,
    @inject("BuildArcaneNodeButtonCommand")
    buildArcaneNode: IBuildArcaneNodeButtonCommand,
    @inject("BuildHealingShrineButtonCommand")
    buildHealingShrine: IBuildHealingShrineButtonCommand,
    @inject("BuildTrainingGroundButtonCommand")
    buildTrainingGround: IBuildTrainingGroundButtonCommand
  ) {
    this._buttonMap = {
      BACK_TO_MAIN: backToMainButtonCommand,
      SHOW_GRID: showGridButtonCommand,
      SHOW_COLORS: showColorsButtonCommand,
      EXIT: exitButtonCommand,
      ATTACK: attackButtonCommand,
      BUILD: buildButtonCommand,
      HISTORY: historyCommand,
      EXIT_BUILD: exitBuildButtonCommand,
      BUILD_HIDE: buildHideout,
      BUILD_ARM: buildArmory,
      BUILD_WATCH: buildWatchTower,
      BUILD_NODE: buildArcaneNode,
      BUILD_SHRINE: buildHealingShrine,
      BUILD_TRAINING: buildTrainingGround,
    };
  }

  public ApplyButton(
    buttonName: string,
    scene: Scene,
    parentContainer: GameObjects.Container
  ): GameObjects.Container {
    const button = this._buttonMap[buttonName];
    if (button) {
      return button.Execute(scene, parentContainer);
    } else {
      throw new Error("No button with that name");
    }
  }
}
