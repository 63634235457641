import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { class: "flex-row justify-center" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("h1", { class: "py-10 my-10 px-2 mx-2 text-center text-primary text-bold" }, " Page Not Found ", -1)
        ])),
        _: 1
      }),
      _createVNode(_component_v_row, { class: "flex-row justify-center" }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("p", { class: "px-2 mx-2 text-center text-secondary" }, [
            _createTextVNode(" If you believe this is a mistake. Please go to "),
            _createElementVNode("a", { href: "https://discord.gg/cynduqjv" }, "our discord"),
            _createTextVNode(" then go to #bug-report channel. Once there submit the issue that you have and an engineer will create a ticket ")
          ], -1)
        ])),
        _: 1
      })
    ]),
    _: 1
  }))
}