import {
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import { store } from ".";
import IAlert from "@/core/models/Alert/IAlert";

@Module({
  name: "alert",
  dynamic: true,
  store: store,
})
class UIManagerModule extends VuexModule {
  private error?: IAlert | undefined = undefined;
  private _loading = false;

  public get Loading(): boolean {
    return this._loading;
  }

  public get Alert(): IAlert {
    return this.error || { icon: "", message: "" };
  }

  public get HasAlert(): boolean {
    return this.error != undefined;
  }

  @Mutation
  public AddAlert(value: IAlert): void {
    this.error = value;
  }

  @Mutation
  public ClearAlert(): void {
    this.error = undefined;
  }

  @Mutation
  public ToggleLoading(): void {
    this._loading = !this._loading;
  }
}

export default getModule(UIManagerModule);
