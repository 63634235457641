import { injectable } from "inversify-props";
import "reflect-metadata";
import ICampaignViewViewModel from "./ICampaignViewViewModel";
import phaserConfig from "@/core/constants/phaserConfig";
import Phaser from "phaser";

@injectable()
export default class CampaignViewViewModel implements ICampaignViewViewModel {
  private _game!: Phaser.Game | undefined;

  public get Game(): Phaser.Game {
    return this._game as Phaser.Game;
  }

  public DestroyGame(): void {
    if (this._game) {
      this._game.destroy(true);
      this._game = undefined;
    }
  }

  public StartGame(parent: string): void {
    this._game = new Phaser.Game({ ...phaserConfig(parent) });
  }
}
