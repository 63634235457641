import { inject, injectable } from "inversify-props";
import "reflect-metadata";
import IProfileViewModel from "./IProfileViewModel";
import ILanguageService from "@/core/services/LanguageService/ILanguageService";
import Session from "@/store/Session";
import { AllTranslationKeys } from "@/core/constants/AllTranslationKeys";
import IStorageService from "@/core/services/StorageService/IStorageService";
import UIManager from "@/store/UIManager";
import IUserService from "@/core/services/UserService/IUserService";
import IAuthService from "@/core/services/AuthService/IAuthService";

@injectable()
export default class ProfileViewModel implements IProfileViewModel {
  private readonly _languageService: ILanguageService;
  private readonly _storageService: IStorageService;
  private readonly _userService: IUserService;
  private readonly _authService: IAuthService;

  private email = "";
  private pass = "";
  private username = "";
  private picURI = "";
  private showDialog = false;
  private file: File | undefined;

  public constructor(
    @inject("LanguageService") languageService: ILanguageService,
    @inject("StorageService") storageService: IStorageService,
    @inject("UserService") userService: IUserService,
    @inject("AuthService") authService: IAuthService
  ) {
    this._languageService = languageService;
    this._storageService = storageService;
    this._userService = userService;
    this._authService = authService;
  }

  public get ChangePictureLabel(): string {
    return this._languageService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.CHANGE_PICTURE_LABEL);
  }

  public get ChangeEmailLabel(): string {
    return this._languageService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.CHANGE_EMAIL_LABEL);
  }

  public get ChangePassLabel(): string {
    return this._languageService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.CHANGE_PASS_LABEL);
  }

  public get ChangeUserNameLabel(): string {
    return this._languageService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.CHANGE_USERNAME_LABEL);
  }

  public get PassLabel(): string {
    return this._languageService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.PASS);
  }

  public get EmailLabel(): string {
    return this._languageService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.EMAIL);
  }

  public get UsernameLabel(): string {
    return this._languageService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.USERNAME);
  }

  public get ShowDialog(): boolean {
    return this.showDialog;
  }

  public set Email(value: string) {
    this.email = value;
  }

  public get Email(): string {
    return this.email;
  }

  public set Pass(value: string) {
    this.pass = value;
  }

  public get Pass(): string {
    return this.pass;
  }

  public set Username(value: string) {
    this.username = value;
  }

  public get Username(): string {
    return this.username;
  }

  public set File(value: File | undefined) {
    this.file = value;
  }

  public get File(): File | undefined {
    return this.file;
  }

  public get PictureURI(): string {
    return this.picURI;
  }

  public ToggleDialog(): void {
    this.showDialog = !this.showDialog;
  }

  public async MountUser(): Promise<void> {
    UIManager.ToggleLoading();
    this.picURI = await this._storageService.GetFileByNameAsync(
      `profilePictures/${Session.UserId.toString()}.jpg`
    );
    const user = await this._userService.GetMyFields();
    this.email = user?.email;
    this.username = user?.username;
    UIManager.ToggleLoading();
  }

  public async UpdateEmail(): Promise<void> {
    UIManager.ToggleLoading();
    await this._userService.UpdateMyUserField<string>("email", this.email);
    UIManager.ToggleLoading();
  }

  public async UpdatePassword(): Promise<void> {
    UIManager.ToggleLoading();
    await this._authService.ChangePassword(this.pass);
    UIManager.ToggleLoading();
  }

  public async UpdateUserName(): Promise<void> {
    UIManager.ToggleLoading();
    await this._userService.UpdateMyUserField<string>(
      "username",
      this.username
    );
    UIManager.ToggleLoading();
  }

  public async UpdatePicture(): Promise<void> {
    UIManager.ToggleLoading();
    const newURL = await this._storageService.AddFileAsync(
      `/profilePictures/${Session.UserId}.jpg`,
      this.file as File
    );
    if (newURL) {
      this.picURI = newURL;
    }
    this.showDialog = false;
    UIManager.ToggleLoading();
  }
}
