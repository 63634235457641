import "reflect-metadata";
import IShowColorsButtonCommand from "./IShowColorsButtonCommand";
import IHexGridBuilder from "@/core/builders/HexGridBuilder/IHexGridBuilder";
import { inject, injectable } from "inversify-props";
import { Scene, GameObjects } from "phaser";
import MapScene from "@/core/game/scenes/MapScene";

@injectable()
export default class ShowColorsButtonCommand
  implements IShowColorsButtonCommand
{
  private readonly _hexGridBuilder: IHexGridBuilder;

  public constructor(
    @inject("HexGridBuilder") hexGridBuilder: IHexGridBuilder
  ) {
    this._hexGridBuilder = hexGridBuilder;
  }

  public Execute<T extends Scene>(
    scene: T,
    parentContainer: GameObjects.Container
  ): GameObjects.Container {
    const showGridContainer = scene.add.container(
      parentContainer.x + 10,
      parentContainer.y + 175
    );
    scene.events.on("postupdate", () => {
      showGridContainer.setPosition(
        scene.cameras.main.scrollX + parentContainer.x + 25,
        scene.cameras.main.scrollY + parentContainer.y + 250
      );
    });
    const backRect = scene.add.rectangle(0, 0, 320, 50, 0xececec, 0.75);
    backRect.setOrigin(0, 0);
    backRect.setInteractive();
    backRect.on("pointerup", () => {
      if ((scene as Scene as MapScene).VM.IsShowColors) {
        (scene as Scene as MapScene).VM.IsShowColors = false;
        (scene as Scene as MapScene).Hexes = this._hexGridBuilder.Build(scene);
      } else {
        (scene as Scene as MapScene).VM.IsShowColors = true;
        (scene as Scene as MapScene).Hexes = this._hexGridBuilder.Build(scene);
      }
    });
    showGridContainer.add(backRect);
    const backText = scene.add.text(55, 5, "Toggle Colors", {
      fontSize: "30px",
      fontFamily: "Arial",
      color: "#000000",
    });
    showGridContainer.setDepth(100);
    showGridContainer.add(backText);
    parentContainer.add(showGridContainer);
    return showGridContainer;
  }
}
