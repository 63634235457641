<template>
  <v-container>
    <v-row>
      <v-col>
        <v-row>
          <v-col cols="12">
            <v-text-field
              :label="viewModel.EmailLabel"
              rounded
              variant="outlined"
              :error="
                viewModel.Errors.find(
                  (e) => e.property === 'Email'
                )?.errors?.at(0) != undefined
              "
              :error-messages="
                viewModel.Errors.find((e) => e.property === 'Email')?.errors
              "
              :model-value="viewModel.Email"
              @update:model-value="(e:string) => viewModel.Email = e"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              rounded
              :label="viewModel.PassLabel"
              variant="outlined"
              type="password"
              :model-value="viewModel.Password"
              @update:model-value="(e:string) => viewModel.Password = e"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              :disabled="
                (viewModel.Errors.filter((e) => e.property === 'Email').at(0)
                  ?.errors?.length as number) > 0 || viewModel.Email.length < 1
              "
              block
              rounded
              elevation="3"
              size="large"
              class="bg-primary"
              @click="viewModel.Submit()"
              >{{ viewModel.SubmitLabel }}</v-btn
            >
          </v-col>
          <v-col cols="12">
            <v-btn block rounded size="large" class="bg-accent">
              Forgot Password
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import IBasicLoginViewModel from "@/core/viewModels/BasicLoginViewModel/IBasicLoginViewModel";
import { Vue } from "vue-class-component";
import { InjectReactive } from "vue-property-decorator";

export default class BasicLoginView extends Vue {
  @InjectReactive("BasicLoginViewModel") viewModel!: IBasicLoginViewModel;
}
</script>
