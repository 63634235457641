<template>
  <v-container>
    <v-card v-if="viewModel.MyCampaigns.length === 0" class="bg-surface">
      <v-card-text>{{ viewModel.NoCampaigns }}</v-card-text>
      <v-card-actions
        ><v-btn
          class="bg-primary"
          block
          @click="viewModel.FetchMyCampaigns()"
          >{{ viewModel.TryAgainLabel }}</v-btn
        ></v-card-actions
      >
    </v-card>
    <CampaignCard
      v-for="campaign in viewModel.MyCampaigns"
      :key="campaign._id"
      :campaign="campaign"
    />
  </v-container>
</template>

<script lang="ts">
import CampaignCard from "@/components/CampaignCard.vue";
import ICampaignListViewModel from "@/core/viewModels/CampaignListViewModel/ICampaignListViewModel";
import { Options, Vue } from "vue-class-component";
import { InjectReactive } from "vue-property-decorator";

@Options({
  components: { CampaignCard },
})
export default class CampaignListView extends Vue {
  @InjectReactive("CampaignListViewModel")
  private readonly viewModel!: ICampaignListViewModel;

  public async mounted(): Promise<void> {
    await this.viewModel.FetchMyCampaigns();
    this.viewModel.PageDown();
  }
}
</script>
