import { injectable } from "inversify-props";
import "reflect-metadata";
import IPinchEventCommand from "./IPinchEventCommand";
import { Scene, Math } from "phaser";
import { Pinch } from "phaser3-rex-plugins/plugins/gestures";
import MapScene from "@/core/game/scenes/MapScene";

@injectable()
export default class PinchEventCommand implements IPinchEventCommand {
  private minZoom = 1; // Set your minimum zoom (e.g., half the original size)
  private maxZoom = 3;
  public Execute<T extends Scene>(scene: T): void {
    const pinch = new Pinch(scene);
    pinch.on("drag1", (dragScale: Pinch) => {
      if (!(scene as Scene as MapScene).VM.IsMenu) {
        const drag1Vector = dragScale.drag1Vector;
        scene.cameras.main.scrollX -= drag1Vector.x / scene.cameras.main.zoom;
        scene.cameras.main.scrollY -= drag1Vector.y / scene.cameras.main.zoom;
      }
    });
    pinch.on("pinch", (dragScale: Pinch) => {
      if (!(scene as Scene as MapScene).VM.IsMenu) {
        (scene as Scene as MapScene).VM.IsHold = false;
        const scalefactor = dragScale.scaleFactor;

        // Calculate the new zoom value
        let newZoom = scene.cameras.main.zoom * scalefactor;

        // Clamp the zoom to stay between minZoom and maxZoom
        newZoom = Math.Clamp(newZoom, this.minZoom, this.maxZoom);

        // Apply the clamped zoom level
        scene.cameras.main.zoom = newZoom;
      }
    });
  }
}
