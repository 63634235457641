<template>
  <v-card
    class="bg-surface py-2"
    elevation="6"
    :to="`/campaign/${campaign._id}`"
  >
    <v-row align="start">
      <v-col cols="4">
        <v-img rounded class="ml-3 mt-2" :src="campaign?.map"></v-img>
      </v-col>
      <v-col cols="8">
        <v-card-title>{{ campaign.name }}</v-card-title>
        <v-card-text>{{ campaign.description }}</v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import ICampaign from "@/core/models/Campaign/ICampaign";
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export default class CampaignCard extends Vue {
  @Prop() campaign!: ICampaign;
}
</script>

<style scoped>
.full-height-img {
  height: 100%;
}
</style>
