<template>
  <div :id="`gameContainer-${route.params.id}`"></div>
</template>

<script lang="ts">
import ICampaignViewViewModel from "@/core/viewModels/CampaignViewViewModel/ICampaignViewViewModel";
import { Vue } from "vue-class-component";
import { InjectReactive } from "vue-property-decorator";
import { useRoute } from "vue-router";

export default class CampaignView extends Vue {
  @InjectReactive("CampaignViewViewModel")
  private readonly viewModel!: ICampaignViewViewModel;
  private route = useRoute();

  public mounted(): void {
    this.viewModel.StartGame(`gameContainer-${this.route.params.id as string}`);
  }

  public unmounted(): void {
    this.viewModel.DestroyGame();
  }
}
</script>
