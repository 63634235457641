import { inject, injectable } from "inversify-props";
import "reflect-metadata";
import IHexHistoryViewModel from "./IHexHistoryViewModel";
import ICampaignService from "@/core/services/CampaignService/ICampaignService";
import IGameEvent from "@/core/models/Campaign/IGameEvent";
import ILanguageService from "@/core/services/LanguageService/ILanguageService";
import Session from "@/store/Session";
import { AllTranslationKeys } from "@/core/constants/AllTranslationKeys";

@injectable()
export default class HexHistoryViewModel implements IHexHistoryViewModel {
  private readonly _campaignService: ICampaignService;
  private readonly _languageService: ILanguageService;

  private pageNum = 0;
  private hexId = "";
  private skipWatch = false;
  private events: Array<IGameEvent> = [];

  public constructor(
    @inject("CampaignService") campaignService: ICampaignService,
    @inject("LanguageService") languageService: ILanguageService
  ) {
    this._campaignService = campaignService;
    this._languageService = languageService;
  }

  public get HexEvents(): Array<IGameEvent> {
    return this.events;
  }

  public set HexId(value: string) {
    this.hexId = value;
  }

  public get HexHistoryLabel(): string {
    return this._languageService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.HEX_HISTORY_LABEL);
  }

  public get SkipWatch(): boolean {
    return this.skipWatch;
  }

  public get PageNumber(): number {
    return this.pageNum;
  }

  public async FetchHexHistory(): Promise<void> {
    const res = await this._campaignService.FetchHexHistoryAsync(
      this.hexId,
      this.pageNum
    );
    if (res.length < 1) {
      this.skipWatch = this.pageNum > 0;
      this.pageNum = Math.max(this.pageNum - 1, 0);
    }
    this.events = [...this.events, ...res].filter(
      (event, index, self) =>
        index === self.findIndex((e) => e._id === event._id)
    );
  }

  public get NextPageLabel(): string {
    return this._languageService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.NEXT_PAGE);
  }

  public NextPageClick(): void {
    this.skipWatch = false;
    this.pageNum += 1;
  }

  public get NoEvents(): string {
    return this._languageService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.NO_EVENTS);
  }

  public get TryAgainLabel(): string {
    return this._languageService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.TRY_AGAIN);
  }
}
