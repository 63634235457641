import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import PageNotFoundView from "@/views/PageNotFoundView.vue";
import LoginView from "@/views/LoginView.vue";
import HomeView from "@/views/HomeView.vue";
import Session from "@/store/Session";
import CampaignListView from "@/views/CampaignListView.vue";
import CampaignView from "@/views/CampaignView.vue";
import { container } from "inversify-props";
import ICampaignService from "@/core/services/CampaignService/ICampaignService";
import CampaignService from "@/core/services/CampaignService/CampaignService";
import HexHistoryView from "@/views/HexHistoryView.vue";
import ProfileView from "@/views/ProfileView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/campaign-list",
    name: "CampaignList",
    component: CampaignListView,
  },
  {
    path: "/campaign/:id",
    name: "Campaign",
    component: CampaignView,
  },
  {
    path: "/campaign/:id/hex/:hexid",
    name: "HexHistory",
    component: HexHistoryView,
  },
  {
    path: "/home",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/profile",
    name: "Profile",
    component: ProfileView,
  },
  {
    path: "/:catchAll(.*)",
    name: "not-found",
    component: PageNotFoundView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (
    // Allows the user to go to the login/forgo/sign up pages if there's no token present
    ["/", "/forgot-password", "/sign-up"].includes(to.path) &&
    !Session.Token
  ) {
    return next();
    // Redirects the user to the page they're on if they have a token
  } else if (to.path === "/" && Session.Token) {
    router.push({ name: from.name });
    return next();
    // Redirects the user away from a non login page if they don't have a token
  } else if (
    !["/", "/forgot-password", "/sign-up"].includes(to.path) &&
    !Session.Token
  ) {
    router.push({ name: "Login" });
    return next();
  } else if (
    ["Campaign", "HexHistory"].includes(to.name?.toString() || "") &&
    !(await container
      .resolve<ICampaignService>(CampaignService)
      .IsMyCampaignAsync(to.params?.id as string))
  ) {
    router.push({ name: from.name });
    return next();
  } else {
    return next();
  }
});

export default router;
