import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { class: "flex-row justify-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { class: "d-flex justify-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_avatar, {
                size: "130",
                image: _ctx.viewModel.PictureURI
              }, null, 8, ["image"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                rounded: "",
                block: "",
                elevation: "3",
                size: "large",
                class: "bg-primary",
                onClick: _cache[0] || (_cache[0] = () => _ctx.viewModel.ToggleDialog())
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.viewModel.ChangePictureLabel), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_dialog, {
        persistent: "",
        modelValue: _ctx.viewModel.ShowDialog,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.viewModel.ShowDialog) = $event)),
        "max-width": "500px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "headline" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.viewModel.ChangePassLabel), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_file_input, {
                    label: "Upload New Picture",
                    accept: "image/*",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = (e) => _ctx.viewModel.File = e),
                    "model-value": _ctx.viewModel.File
                  }, null, 8, ["model-value"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    class: "bg-accent",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.viewModel.ToggleDialog()))
                  }, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createTextVNode("Cancel")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    class: "bg-primary",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.viewModel.UpdatePicture()))
                  }, {
                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createTextVNode("Save")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                rounded: "",
                label: _ctx.viewModel.UsernameLabel,
                variant: "outlined",
                "model-value": _ctx.viewModel.Username,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = (e) => _ctx.viewModel.Username = e)
              }, null, 8, ["label", "model-value"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                rounded: "",
                block: "",
                elevation: "3",
                size: "large",
                class: "bg-primary",
                onClick: _cache[6] || (_cache[6] = () => _ctx.viewModel.UpdateUserName())
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.viewModel.ChangeUserNameLabel), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                rounded: "",
                label: _ctx.viewModel.EmailLabel,
                variant: "outlined",
                "model-value": _ctx.viewModel.Email,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = (e) => _ctx.viewModel.Email = e)
              }, null, 8, ["label", "model-value"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                rounded: "",
                block: "",
                elevation: "3",
                size: "large",
                class: "bg-primary",
                onClick: _cache[8] || (_cache[8] = () => _ctx.viewModel.UpdateEmail())
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.viewModel.ChangeEmailLabel), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                rounded: "",
                label: _ctx.viewModel.PassLabel,
                variant: "outlined",
                "model-value": _ctx.viewModel.Pass,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = (e) => _ctx.viewModel.Pass = e)
              }, null, 8, ["label", "model-value"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                rounded: "",
                block: "",
                elevation: "3",
                size: "large",
                class: "bg-primary",
                onClick: _cache[10] || (_cache[10] = () => _ctx.viewModel.UpdatePassword())
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.viewModel.ChangePassLabel), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}