import IValidationContext from "./IValidationContext";
import IValidationStrategy from "./IValidationStrategy";

export default class ValidationContext implements IValidationContext {
  private _strat = {} as IValidationStrategy;

  public SetStrategy(strat: IValidationStrategy): IValidationContext {
    this._strat = strat;
    return this;
  }

  public Validate<T>(value: T): Array<string> {
    return this._strat.Validate(value);
  }
}
