import { inject, injectable } from "inversify-props";
import "reflect-metadata";
import { FirebaseApp, initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { FIREBASE_CONFIG } from "@/core/constants/firebaseConfig";
import IMessageService from "./IMessageService";
import { AllTranslationKeys } from "@/core/constants/AllTranslationKeys";
import IFirebaseWrapper from "@/core/wrappers/FirebaseWrapper/IFirebaseWrapper";

@injectable()
export default class MessageService implements IMessageService {
  private readonly _firebase: IFirebaseWrapper;

  public constructor(@inject("FirebaseWrapper") firebase: IFirebaseWrapper) {
    this._firebase = firebase;
  }

  public async RequestPermission(): Promise<string> {
    try {
      const perm = await Notification?.requestPermission();
      if (perm !== "granted") {
        return AllTranslationKeys.ERROR_CLIENT_FIREBASE_TOKEN_NOT_GIVEN;
      }
      const messaging = this._firebase.GetMessaging();

      const token = await getToken(messaging, {
        vapidKey: process.env.VUE_APP_FIREBASE_VAPID,
      });
      return token || AllTranslationKeys.ERROR_CLIENT_FIREBASE_TOKEN_NOT_GIVEN;
    } catch (e) {
      return AllTranslationKeys.ERROR_CLIENT_FIREBASE_TOKEN_ERROR;
    }
  }
}
