import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "bg-surface py-2",
    elevation: "6",
    to: `/campaign/${_ctx.campaign._id}`
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { align: "start" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "4" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_img, {
                rounded: "",
                class: "ml-3 mt-2",
                src: _ctx.campaign?.map
              }, null, 8, ["src"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "8" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.campaign.name), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.campaign.description), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["to"]))
}