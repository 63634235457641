import "reflect-metadata";
import { inject, injectable } from "inversify-props";
import IEmailValidationStrategy from "./IEmailValidationStrategy";
import ILanguageService from "@/core/services/LanguageService/ILanguageService";
import Session from "@/store/Session";
import { AllTranslationKeys } from "@/core/constants/AllTranslationKeys";

@injectable()
export default class EmailValidationStrategy
  implements IEmailValidationStrategy
{
  @inject("LanguageService")
  private readonly _langService!: ILanguageService;

  private readonly validations = [
    (v: string) =>
      !new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/).test(v)
        ? this._langService
            .SetLangauge(Session.Langauge)
            .GetTranslation(AllTranslationKeys.ERROR_EMAIL_INCORRECT_FORMAT)
        : "",
  ];

  public Validate<T>(value: T): Array<string> {
    return this.validations
      .map((valid) => valid(value as string))
      .filter(Boolean);
  }
}
