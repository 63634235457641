<template>
  <v-card class="bg-surface py-2" elevation="6">
    <v-row align="start">
      <v-col cols="8">
        <v-card-title
          >{{
            event.eventType === "COMBAT"
              ? viewModel.AttackLabel
              : viewModel.BuildLabel
          }}
          {{ event._id?.toString().slice(-4) }}</v-card-title
        >
        <v-card-text>
          <p>
            {{ viewModel.OnLabel }}
            {{
              new Date(event.initiated).toLocaleDateString(session.Langauge, {
                weekday: "long",
                month: "short",
                day: "numeric",
                year: "numeric",
              })
            }}
            {{ (event.players.at(0) as IUser)?.username }}
            {{ viewModel.AttackedLabel }}
            {{
              (event.players.at(-1) as IUser)?.username !==
              (event.players.at(0) as IUser)?.username
                ? (event.players.at(-1) as IUser)?.username
                : undefined
            }}
          </p>
          <p>
            <span
              ><strong>{{ viewModel.EventIsLabel }}: </strong></span
            >
            {{
              event?.winner
                ? viewModel.EventResultLabel("RESOLVED")
                : viewModel.EventResultLabel("ACTIVE")
            }}
          </p>
          <p v-if="event.winner">{{ event?.alternateWinCon }}</p>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import IGameEvent from "@/core/models/Campaign/IGameEvent";
import IUser from "@/core/models/User/IUser";
import IHexEventCardViewModel from "@/core/viewModels/HexEventCardViewModel/IHexEventCardViewModel";
import Session from "@/store/Session";
import { Vue } from "vue-class-component";
import { InjectReactive, Prop } from "vue-property-decorator";

export default class HexEventCard extends Vue {
  @InjectReactive("HexEventCardViewModel")
  private readonly viewModel!: IHexEventCardViewModel;
  @Prop() event!: IGameEvent;
  private readonly session = Session;
}
</script>

<style scoped>
.full-height-img {
  height: 100%;
}
</style>
