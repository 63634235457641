import { injectable } from "inversify-props";
import "reflect-metadata";
import ITouchAndHoldEventCommand from "./ITouchAndHoldEventCommand";
import { Input, Scene } from "phaser";
import MapScene from "@/core/game/scenes/MapScene";

@injectable()
export default class TouchAndHoldEventCommand
  implements ITouchAndHoldEventCommand
{
  private readonly _holdDuration = 2000;

  public Execute<T extends Scene>(scene: T): void {
    scene.input.on("pointerdown", (pointer: Input.Pointer) => {
      (scene as Scene as MapScene).VM.IsHold = true;
    });

    scene.input.on("pointerup", (pointer: Input.Pointer) => {
      (scene as Scene as MapScene).VM.IsHold = false;
    });
  }
}
