import IHexGridBuilder from "@/core/builders/HexGridBuilder/IHexGridBuilder";
import ISceneEventCommandHandler from "@/core/commands/SceneEventCommandHandler/ISceneEventCommandHandler";
import IMenuFactory from "@/core/factories/MenuFactory/IMenuFactory";
import IHex from "@/core/models/FunctionData/Campaign/IHex";
import IMapSceneViewModel from "@/core/viewModels/MapSceneViewModel/IMapSceneViewModel";
import Session from "@/store/Session";
import { inject, injectable } from "inversify-props";
import { GameObjects, Scene } from "phaser";
import "reflect-metadata";

@injectable()
export default class MapScene extends Scene {
  private readonly _viewModel!: IMapSceneViewModel;
  private readonly _sceneEventHandler!: ISceneEventCommandHandler;
  private readonly _hexGridBuilder: IHexGridBuilder;
  private readonly _menuFactory: IMenuFactory;
  private readonly _maxRadius = 55;
  private circleRadius = 0;
  private map!: GameObjects.Image;
  private hexes!: Array<GameObjects.Polygon>;
  private holdCircle!: GameObjects.Graphics;
  private mainMenu!: GameObjects.Container;

  public constructor(
    @inject("MapSceneViewModel") viewModel: IMapSceneViewModel,
    @inject("SceneEventCommandHandler")
    sceneEventHandler: ISceneEventCommandHandler,
    @inject("HexGridBuilder") hexBuilder: IHexGridBuilder,
    @inject("MenuFactory") menuFactory: IMenuFactory
  ) {
    super({ key: "MapScene" });
    this._viewModel = viewModel;
    this._sceneEventHandler = sceneEventHandler;
    this._hexGridBuilder = hexBuilder;
    this._menuFactory = menuFactory;
  }

  public get VM(): IMapSceneViewModel {
    return this._viewModel;
  }

  public OpenHexMenu(hex: IHex): void {
    this._menuFactory.CreateHexMenu(this, hex);
  }

  public OpenBuildMenu(hex: IHex): void {
    if (this._viewModel.Campaign.campaignType === "FANTASY_SKIRMISH") {
      this._menuFactory.CreateSkirmishBuildMenu(this, hex);
    }
  }

  public preload() {
    this.load.rexAwait((successCallBack, failureCallBack) => {
      this._viewModel
        .SetCampaign()
        .then(() => successCallBack())
        .catch(() => failureCallBack());
    });
    this.load.start();
  }

  public create() {
    // not ready yet -> this.hudLayer = this.add.layer();
    this.map = this.add.image(0, 0, "mapBackground").setOrigin(0, 0);
    this.map.setDepth(-1);
    this.hexes = this._hexGridBuilder.Build(this);
    const userFirstHex = this.hexes.find(
      (e) => (e?.getData("dbHex") as IHex).owner === Session.UserId
    );
    this.cameras.main.setBounds(0, 0, this.map.width, this.map.height);
    this.cameras.main.setScroll(userFirstHex?.x || 0, userFirstHex?.y || 0);
    this.holdCircle = this.add.graphics();
    this.mainMenu = this._menuFactory.CreateMenu(this);
    this._sceneEventHandler.ApplyEvent("DRAG", this);
    //this._sceneEventHandler.ApplyEvent("PINCH", this);
    this._sceneEventHandler.ApplyEvent("TOUCH_AND_HOLD", this);
  }

  public update() {
    if (this.VM.IsChangeGrid) {
      this.VM.IsChangeGrid = false;
      this.hexes = this._hexGridBuilder.Build(this);
    }
    if (this.VM.IsHold && !this.VM.IsMenu && !this.VM.IsBuildMenu) {
      this.circleRadius += 2;
      if (this.circleRadius >= this._maxRadius) {
        this.VM.IsMenu = true;
      }
      this.holdCircle.clear();
      this.holdCircle.fillStyle(0x2c3e50, 0.55);
      this.holdCircle.fillCircle(
        this.input.activePointer.worldX,
        this.input.activePointer.worldY,
        this.circleRadius
      );
    } else {
      this.circleRadius = 0;
      this.holdCircle.clear();
    }

    if (this.VM.IsMenu) {
      this.mainMenu.setVisible(this.VM.IsMenu);
    }
  }

  public get Hexes(): Array<GameObjects.Polygon> {
    return this.hexes;
  }

  public set Hexes(hexes: Array<GameObjects.Polygon>) {
    this.hexes = hexes;
  }
}
