import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "bg-surface py-2",
    elevation: "6"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { align: "start" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "8" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.event.eventType === "COMBAT"
              ? _ctx.viewModel.AttackLabel
              : _ctx.viewModel.BuildLabel) + " " + _toDisplayString(_ctx.event._id?.toString().slice(-4)), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, _toDisplayString(_ctx.viewModel.OnLabel) + " " + _toDisplayString(new Date(_ctx.event.initiated).toLocaleDateString(_ctx.session.Langauge, {
                weekday: "long",
                month: "short",
                day: "numeric",
                year: "numeric",
              })) + " " + _toDisplayString((_ctx.event.players.at(0) as IUser)?.username) + " " + _toDisplayString(_ctx.viewModel.AttackedLabel) + " " + _toDisplayString((_ctx.event.players.at(-1) as IUser)?.username !==
              (_ctx.event.players.at(0) as IUser)?.username
                ? (_ctx.event.players.at(-1) as IUser)?.username
                : undefined), 1),
                  _createElementVNode("p", null, [
                    _createElementVNode("span", null, [
                      _createElementVNode("strong", null, _toDisplayString(_ctx.viewModel.EventIsLabel) + ": ", 1)
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.event?.winner
                ? _ctx.viewModel.EventResultLabel("RESOLVED")
                : _ctx.viewModel.EventResultLabel("ACTIVE")), 1)
                  ]),
                  (_ctx.event.winner)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.event?.alternateWinCon), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}