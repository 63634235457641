export const SpanishTranslations = {
  EMAIL: "Correo electrónico",
  PASS: "Contraseña",
  ERROR_EMAIL_INCORRECT_FORMAT: "Tu correo electrónico no es válido.",
  SERVER_ERROR_UNKNOWN: "Error desconocido del servidor. Contacta al soporte.",
  ERROR_SERVER_LOGIN: "Correo electrónico y/o contraseña incorrectos.",
  TITLE_BASIC_LOGIN: "Iniciar sesión",
  SUBMIT: "Enviar",
  SIGN_UP: "Registrarse",
  NO_CAMPAIGNS: "Parece que no tienes campañas disponibles.",
  TRY_AGAIN: "Intenta de nuevo",
  ERROR_SERVER_MUST_HAVE_BODY: "La solicitud no tenía cuerpo.",
  ERROR_SERVER_UNAUTHORIZED: "No estás autorizado.",
  ERROR_CLIENT_FIREBASE_TOKEN_ERROR:
    "Error al intentar recibir un token de Firebase.",
  ERROR_CLIENT_FIREBASE_TOKEN_NOT_GIVEN:
    "No se recibió un token de Firebase, sin error.",
  ERROR_SERVER_HEX_OWNED_BY_ATTACKER: "Ya controlas este hexágono.",
  ERROR_SERVER_ATTACKER_HAS_NO_NEIGHBOR:
    "Tu hexágono no es vecino del hexágono atacado.",
  ERROR_SERVER_HEX_EXISTING_UNRESOLVED_ACTION:
    "Este hexágono está bloqueado hasta que se resuelva una acción pendiente.",
  ERROR_SERVER_HEX_NOT_ON_CAMPAIGN: "¿Cómo estás atacando este hexágono?",
  ERROR_SERVER_HEX_NOT_OWNED: "Hexágono no poseído.",
  ERROR_SERVER_USER_HAS_2_ATTACKS_THIS_TURN:
    "Ya tienes dos acciones de ataque en este turno.",
  ERROR_SERVER_USER_NOT_IN_CAMPAIGN: "¿Cómo estás atacando este hexágono?",
  SERVER_SUCCESS_ATTACK: "¡Ataque exitoso! Revisa tu página de eventos.",
  SERVER_SUCCESS_BUILD: "¡Construcción exitosa!",
  SERVER_ERROR_CANNOT_BUILD_NOT_YOUR_HEX: "¡No posees este hexágono!",
  ERROR_SERVER_NO_ARMY_FOR_CAMPAIGN: "No tienes un ejército para esta campaña.",
  ERROR_SERVER_NO_SUCH_STRUCT:
    "La estructura que intentas construir no existe.",
  ERROR_SERVER_CANNOT_AFFORD:
    "No tienes suficientes recursos para construir esta estructura.",
  ERROR_SERVER_HEX_NOT_OWNED_BUILDER:
    "No posees este hexágono, por lo que no puedes construir en él.",
  CHANGE_PICTURE_LABEL: "Cambiar Foto de Perfil",
  CHANGE_USERNAME_LABEL: "Cambiar Nombre de Usuario",
  CHANGE_EMAIL_LABEL: "Cambiar Correo Electrónico",
  CHANGE_PASS_LABEL: "Cambiar Contraseña",
  USERNAME: "Nombre de Usuario",
  SUCCESS_USER_CHANGE: "¡Usuario actualizado con éxito!",
  ERROR_SERVER_FAILED_USER_UPDATE:
    "Error al actualizar la información del usuario. Por favor, inténtalo de nuevo.",
  ERROR_SERVER_PASSWORD_MUST_HAVE_CAPITAL:
    "La contraseña debe contener al menos una letra mayúscula.",
  ERROR_SERVER_PASSWORD_MUST_HAVE_LOWERCASE:
    "La contraseña debe contener al menos una letra minúscula.",
  ERROR_SERVER_PASSWORD_MUST_HAVE_NUMBERS:
    "La contraseña debe contener al menos un número.",
  ERROR_SERVER_PASSWORD_MUST_HAVE_SPECIAL_CHARACTERS:
    "La contraseña debe contener al menos un carácter especial.",
  ERROR_SERVER_PASSWORD_LENGTH:
    "La contraseña debe tener entre 8 y veinte caracteres.",
  NO_EVENTS: "Parece que no hay eventos para este hexágono.",
  ATTACK_LABEL: "ATACAR",
  BUILD_LABEL: "CONSTRUIR",
  ON_LABEL: "En",
  ATTACKED_LABEL: "atacó",
  EVENT_IS_LABEL: "Este evento es",
  RESOLVED: "RESUELTO",
  ACTIVE: "ACTIVO",
  NEXT_PAGE: "Página siguiente",
  HEX_HISTORY_LABEL: "Historial de Hex",
};
