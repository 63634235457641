<template>
  <v-app class="bg-background">
    <v-app-bar v-if="session.Token" class="bg-secondary">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-app-bar-title>Realm Rank</v-app-bar-title>
      <v-btn
        icon="mdi-account-circle"
        to="/profile"
        exact
        :ripple="false"
      ></v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-if="session.Token"
      v-model="drawer"
      :location="$vuetify.display.mobile ? 'bottom' : undefined"
      temporary
    >
      <v-list>
        <v-list-item v-for="route in routes" :key="route.path" :to="route.path">
          <v-list-item-title>{{ route.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-snackbar
      :color="uiManager.Alert.color || 'accent'"
      :model-value="uiManager.HasAlert"
      @update:model-value="uiManager.ClearAlert()"
      timeout="2000"
      timer
    >
      <v-icon v-if="uiManager.Alert.icon" :icon="uiManager.Alert.icon"></v-icon>
      <p>{{ uiManager.Alert.message }}</p>
    </v-snackbar>
    <v-overlay :model-value="uiManager.Loading">
      <v-container>
        <v-row class="ml-16 mt-16 flex-row justify-center">
          <v-progress-circular
            color="primary"
            indeterminate
            size="64"
          ></v-progress-circular>
        </v-row>
      </v-container>
    </v-overlay>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import Session from "./store/Session";
import { ProvideReactive, Ref } from "vue-property-decorator";
import { container } from "inversify-props";
import IBasicLoginViewModel from "./core/viewModels/BasicLoginViewModel/IBasicLoginViewModel";
import BasicLoginViewModel from "./core/viewModels/BasicLoginViewModel/BasicLoginViewModel";
import ILoginViewViewModel from "./core/viewModels/LoginViewViewModel/ILoginViewViewModel";
import LoginViewViewModel from "./core/viewModels/LoginViewViewModel/LoginViewViewModel";
import UIManager from "./store/UIManager";
import ICampaignListViewModel from "./core/viewModels/CampaignListViewModel/ICampaignListViewModel";
import CampaignListViewModel from "./core/viewModels/CampaignListViewModel/CampaignListViewModel";
import ICampaignViewViewModel from "./core/viewModels/CampaignViewViewModel/ICampaignViewViewModel";
import CampaignViewViewModel from "./core/viewModels/CampaignViewViewModel/CampaignViewViewModel";
import IProfileViewModel from "./core/viewModels/ProfileViewModel/IProfileViewModel";
import ProfileViewModel from "./core/viewModels/ProfileViewModel/ProfileViewModel";
import IHexHistoryViewModel from "./core/viewModels/HexHistoryViewModel/IHexHistoryViewModel";
import HexHistoryViewModel from "./core/viewModels/HexHistoryViewModel/HexHistoryViewModel";
import IHexEventCardViewModel from "./core/viewModels/HexEventCardViewModel/IHexEventCardViewModel";
import HexEventCardViewModel from "./core/viewModels/HexEventCardViewModel/HexEventCardViewModel";

export default class App extends Vue {
  private drawer = false;
  @ProvideReactive("BasicLoginViewModel") private readonly basicLoginViewModel =
    container.resolve<IBasicLoginViewModel>(BasicLoginViewModel);
  @ProvideReactive("LoginViewViewModel") private readonly loginViewViewModel =
    container.resolve<ILoginViewViewModel>(LoginViewViewModel);
  @ProvideReactive("CampaignListViewModel")
  private readonly campaignListViewModel =
    container.resolve<ICampaignListViewModel>(CampaignListViewModel);
  @ProvideReactive("CampaignViewViewModel") private readonly campaignViewModel =
    container.resolve<ICampaignViewViewModel>(CampaignViewViewModel);
  @ProvideReactive("ProfileViewModel") private readonly profileViewModel =
    container.resolve<IProfileViewModel>(ProfileViewModel);
  @ProvideReactive("HexHistoryViewModel") private readonly hexHistoryViewModel =
    container.resolve<IHexHistoryViewModel>(HexHistoryViewModel);
  @ProvideReactive("HexEventCardViewModel")
  private readonly hexEventCardViewModel =
    container.resolve<IHexEventCardViewModel>(HexEventCardViewModel);
  @Ref() private session = Session;
  @Ref() private uiManager = UIManager;

  private readonly routes = [
    { path: "/campaign-list", name: "CampaignList", text: "Campaign List" },
  ];

  public mounted() {
    Session.SetLanguage();
  }
}
</script>
