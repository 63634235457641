<template>
  <v-container>
    <v-row class="flex-row justify-center">
      <img class="custom-banner" src="/img/icons/android-chrome-512x512.png" />
    </v-row>
    <v-row>
      <BasicLoginView />
    </v-row>
    <v-row class="mx-1">
      <v-btn
        elevation="6"
        rounded
        class="bg-primary"
        size="large"
        block
        @click="viewModel.SignUp()"
      >
        {{ viewModel.SignUpLabel }}
      </v-btn>
    </v-row>
    <v-row>
      <AuxLoginView />
    </v-row>
    <v-footer app>
      <v-row class="text-right">
        <v-col cols="12">
          <p>{{ version }}</p>
        </v-col>
      </v-row>
    </v-footer>
  </v-container>
</template>

<script lang="ts">
import AuxLoginView from "@/components/AuxLoginView.vue";
import BasicLoginView from "@/components/BasicLoginView.vue";
import ILoginViewViewModel from "@/core/viewModels/LoginViewViewModel/ILoginViewViewModel";
import { Vue, Options } from "vue-class-component";
import { InjectReactive, Ref } from "vue-property-decorator";

@Options({
  components: { BasicLoginView, AuxLoginView },
})
export default class LoginView extends Vue {
  @InjectReactive("LoginViewViewModel")
  readonly viewModel!: ILoginViewViewModel;
  @Ref() readonly basicLoginView!: BasicLoginView;
  @Ref() readonly auxLoginView!: AuxLoginView;
  @Ref() private version = process.env.VUE_APP_VERSION;
}
</script>
<style scoped>
.custom-banner {
  width: 200px;
  height: 160px;
  border-radius: 50%; /* Rounds the image */
}
</style>
