import { inject, injectable } from "inversify-props";
import "reflect-metadata";
import IMenuFactory from "./IMenuFactory";
import { GameObjects, Scene } from "phaser";
import IButtonCommandHandler from "@/core/commands/ButtonCommandHandler/IButtonCommandHandler";
import IHex from "@/core/models/FunctionData/Campaign/IHex";

@injectable()
export default class MenuFactory implements IMenuFactory {
  private readonly _buttonCommander: IButtonCommandHandler;

  public constructor(
    @inject("ButtonCommandHandler") buttonCommander: IButtonCommandHandler
  ) {
    this._buttonCommander = buttonCommander;
  }

  public CreateMenu(scene: Scene): GameObjects.Container {
    const menuContainer = scene.add.container(5, 5);
    const menuBackGround = scene.add.rectangle(
      0,
      0,
      scene.cameras.main.width - 10,
      scene.cameras.main.height - 10,
      0x2c2c2c,
      1
    );
    menuBackGround.setInteractive();
    menuContainer.add(menuBackGround);
    this._buttonCommander.ApplyButton("BACK_TO_MAIN", scene, menuContainer);
    this._buttonCommander.ApplyButton("SHOW_GRID", scene, menuContainer);
    this._buttonCommander.ApplyButton("SHOW_COLORS", scene, menuContainer);
    scene.events.on("postupdate", () => {
      menuBackGround.setPosition(
        scene.cameras.main.scrollX,
        scene.cameras.main.scrollY
      );
    });
    menuBackGround.setOrigin(0, 0);
    menuContainer.setDepth(99);
    menuContainer.setVisible(false);
    return menuContainer;
  }

  public CreateHexMenu(scene: Scene, hex: IHex): void {
    const isCameraHigh =
      scene.input.activePointer.worldY > scene.cameras.main.height / 2;
    const isCameraRight =
      scene.input.activePointer.worldX > scene.cameras.main.width * 0.75;
    const y = isCameraHigh
      ? scene.input.activePointer.worldY - 150
      : scene.input.activePointer.worldY + 150;
    const x = isCameraRight
      ? scene.input.activePointer.worldX - 250
      : scene.input.activePointer.worldX;
    const hexMenuContainer = scene.add.container(x, y);
    const hexMenuBackground = scene.add.rectangle(0, 0, 250, 180, 0xf8f8f8, 1);
    hexMenuBackground.setStrokeStyle(5, 0x000000, 0.85);
    const interMediateBack = scene.add.rectangle(
      3,
      3,
      244,
      174,
      0xffffff,
      0.75
    );
    interMediateBack.setOrigin(0, 0);
    hexMenuBackground.setInteractive();
    const mainText = scene.add.text(
      5,
      5,
      `Actions for Hex at ${hex.coordinates.at(0)},${hex.coordinates.at(1)}`,
      {
        fontFamily: "Arial",
        fontSize: "20px",
        color: "#000000",
      }
    );
    hexMenuContainer.add(hexMenuBackground);
    hexMenuContainer.add(interMediateBack);
    hexMenuContainer.add(mainText);
    hexMenuContainer.setData("hex", hex);
    //  ADD BUTTONS
    this._buttonCommander.ApplyButton("EXIT", scene, hexMenuContainer);
    this._buttonCommander.ApplyButton("ATTACK", scene, hexMenuContainer);
    this._buttonCommander.ApplyButton("BUILD", scene, hexMenuContainer);
    this._buttonCommander.ApplyButton("HISTORY", scene, hexMenuContainer);
    hexMenuBackground.setOrigin(0, 0);
    hexMenuContainer.setDepth(99);
    hexMenuContainer.setVisible(true);
  }

  public CreateSkirmishBuildMenu(scene: Scene, hex: IHex): void {
    const menuContainer = scene.add.container(5, 5);
    const menuBackGround = scene.add.rectangle(
      0,
      0,
      scene.cameras.main.width - 10,
      scene.cameras.main.height - 10,
      0x2c2c2c,
      1
    );
    menuBackGround.setInteractive();
    menuContainer.setData("hex", hex);
    menuContainer.add(menuBackGround);
    // apply buttons
    this._buttonCommander.ApplyButton("EXIT_BUILD", scene, menuContainer);
    this._buttonCommander.ApplyButton("BUILD_HIDE", scene, menuContainer);
    this._buttonCommander.ApplyButton("BUILD_ARM", scene, menuContainer);
    this._buttonCommander.ApplyButton("BUILD_WATCH", scene, menuContainer);
    this._buttonCommander.ApplyButton("BUILD_NODE", scene, menuContainer);
    this._buttonCommander.ApplyButton("BUILD_SHRINE", scene, menuContainer);
    this._buttonCommander.ApplyButton("BUILD_TRAINING", scene, menuContainer);
    scene.events.on("postupdate", () => {
      menuBackGround.setPosition(
        scene.cameras.main.scrollX,
        scene.cameras.main.scrollY
      );
    });
    menuBackGround.setOrigin(0, 0);
    menuContainer.setDepth(99);
    menuContainer.setVisible(true);
  }
}
