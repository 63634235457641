import { injectable } from "inversify-props";
import "reflect-metadata";
import IFirebaseWrapper from "./IFirebaseWrapper";
import { FirebaseApp, initializeApp } from "firebase/app";
import { FIREBASE_CONFIG } from "@/core/constants/firebaseConfig";
import { getMessaging, Messaging } from "firebase/messaging";
import {
  FirebaseStorage,
  getDownloadURL,
  getStorage,
  StorageReference,
} from "firebase/storage";

@injectable()
export default class FirebaseWrapper implements IFirebaseWrapper {
  private readonly _firebase: FirebaseApp;

  public constructor() {
    this._firebase = initializeApp(FIREBASE_CONFIG);
  }

  public GetMessaging(): Messaging {
    return getMessaging(this._firebase);
  }

  public GetStorage(): FirebaseStorage {
    return getStorage(this._firebase);
  }

  public async GetDownloadURL(fileRef: StorageReference): Promise<string> {
    return await getDownloadURL(fileRef);
  }
}
