<template>
  <v-container>
    <v-row class="mb-2" justify="end">
      <v-col cols="9">
        <p class="text-h4 py-2">{{ viewModel.HexHistoryLabel }}</p>
      </v-col>
      <v-col cols="3">
        <v-btn
          class="bg-primary"
          icon
          @click="$router.push({ path: `/campaign/${$route.params.id}` })"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-card v-if="viewModel.HexEvents.length === 0" class="bg-surface">
      <v-card-text>{{ viewModel.NoEvents }}</v-card-text>
      <v-card-actions
        ><v-btn class="bg-primary" block @click="viewModel.FetchHexHistory()">{{
          viewModel.TryAgainLabel
        }}</v-btn></v-card-actions
      >
    </v-card>
    <HexEventCard
      class="my-2"
      v-for="hexEvent in viewModel.HexEvents"
      :key="hexEvent._id"
      :event="hexEvent"
    />
    <v-btn
      class="bg-primary"
      elevation="3"
      block
      @click="viewModel.NextPageClick()"
      >{{ viewModel.NextPageLabel }}</v-btn
    >
  </v-container>
</template>

<script lang="ts">
import HexEventCard from "@/components/HexEventCard.vue";
import IHexHistoryViewModel from "@/core/viewModels/HexHistoryViewModel/IHexHistoryViewModel";
import { Options, Vue } from "vue-class-component";
import { InjectReactive, Watch } from "vue-property-decorator";
@Options({
  components: { HexEventCard },
})
export default class HexHistoryView extends Vue {
  @InjectReactive("HexHistoryViewModel") viewModel!: IHexHistoryViewModel;

  @Watch("viewModel.PageNumber")
  private async watchPageNum(newVal: number) {
    if (this.viewModel.SkipWatch || newVal < 0) {
      return;
    }

    await this.viewModel.FetchHexHistory();
  }

  public async mounted() {
    this.viewModel.HexId = this.$route.params.hexid.toString();
    await this.viewModel.FetchHexHistory();
  }
}
</script>
