import { inject, injectable } from "inversify-props";
import "reflect-metadata";
import IAlertDataFactory from "./IAlertDataFactory";
import ILanguageService from "@/core/services/LanguageService/ILanguageService";
import IAlert from "@/core/models/Alert/IAlert";
import Session from "@/store/Session";
import { AllTranslationKeys } from "@/core/constants/AllTranslationKeys";

@injectable()
export default class AlertDataFactory implements IAlertDataFactory {
  @inject("LanguageService")
  private readonly _languageService!: ILanguageService;

  public CreateAlertData(icon: string, color: string, message: string): IAlert {
    return {
      icon,
      color,
      message: this._languageService
        .SetLangauge(Session.Langauge)
        .GetTranslation(AllTranslationKeys[message as keyof object]),
    };
  }
}
