import "reflect-metadata";
import { injectable } from "inversify-props";
import ILanguageService from "./ILanguageService";
import { EnglishTranslations } from "@/core/constants/EnglishTranslations";
import { SpanishTranslations } from "@/core/constants/SpanishTranslations";

@injectable()
export default class LanguageService implements ILanguageService {
  private _lang = "English";
  private _languages = {
    English: EnglishTranslations,
    Spanish: SpanishTranslations,
  };
  public SetLangauge(language: string): ILanguageService {
    const lang = language.split("-")?.[0];
    switch (lang) {
      case "es":
        this._lang = "Spanish";
        break;
      default:
        this._lang = "English";
        break;
    }
    return this;
  }

  public GetTranslation(key: string): string {
    return this._languages?.[this._lang as keyof { English: any }]?.[
      key as keyof object
    ] as string;
  }
}
