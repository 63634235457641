import { inject, injectable } from "inversify-props";
import "reflect-metadata";
import IHexEventCardViewModel from "./IHexEventCardViewModel";
import ILanguageService from "@/core/services/LanguageService/ILanguageService";
import Session from "@/store/Session";
import { AllTranslationKeys } from "@/core/constants/AllTranslationKeys";

@injectable()
export default class HexEventCardViewModel implements IHexEventCardViewModel {
  private readonly _languageService: ILanguageService;

  public constructor(
    @inject("LanguageService") languageService: ILanguageService
  ) {
    this._languageService = languageService;
  }

  public get AttackLabel(): string {
    return this._languageService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.ATTACK_LABEL);
  }

  public get BuildLabel(): string {
    return this._languageService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.BUILD_LABEL);
  }

  public get OnLabel(): string {
    return this._languageService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.ON_LABEL);
  }

  public get AttackedLabel(): string {
    return this._languageService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.ATTACKED_LABEL);
  }

  public get EventIsLabel(): string {
    return this._languageService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.EVENT_IS_LABEL);
  }

  public EventResultLabel(e: string): string {
    return this._languageService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys[e]);
  }
}
