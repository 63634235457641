import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_CampaignCard = _resolveComponent("CampaignCard")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      (_ctx.viewModel.MyCampaigns.length === 0)
        ? (_openBlock(), _createBlock(_component_v_card, {
            key: 0,
            class: "bg-surface"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.viewModel.NoCampaigns), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    class: "bg-primary",
                    block: "",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.viewModel.FetchMyCampaigns()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.viewModel.TryAgainLabel), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.viewModel.MyCampaigns, (campaign) => {
        return (_openBlock(), _createBlock(_component_CampaignCard, {
          key: campaign._id,
          campaign: campaign
        }, null, 8, ["campaign"]))
      }), 128))
    ]),
    _: 1
  }))
}