import {
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import { store } from ".";
import ISession from "@/core/models/Session/ISession";

@Module({
  name: "session",
  store: store,
  dynamic: true,
})
class SessionModule extends VuexModule {
  private readonly _sessionData: ISession = {
    Token: undefined,
    Language: undefined,
    UserId: undefined,
  };

  public get Token(): string {
    return this._sessionData.Token || "";
  }

  public get UserId(): string {
    return this._sessionData.UserId || "";
  }

  public get Langauge(): string {
    return this._sessionData.Language || "en-US";
  }

  @Mutation
  public SetToken(value: string): void {
    this._sessionData.Token = value;
  }

  @Mutation
  public SetLanguage(): void {
    this._sessionData.Language = navigator.language || "en-US";
  }

  @Mutation
  public SetUserId(value: string): void {
    this._sessionData.UserId = value;
  }
}
export default getModule(SessionModule);
