import { injectable } from "inversify-props";
import "reflect-metadata";
import IDraggableCameraEventCommand from "./IDraggableCameraEventCommand";
import { Input, Scene } from "phaser";
import MapScene from "@/core/game/scenes/MapScene";

@injectable()
export default class DraggableCameraEventCommand
  implements IDraggableCameraEventCommand
{
  Execute<T extends Scene>(scene: T): void {
    scene.input.on("pointerdown", (pointer: Input.Pointer) => {
      (scene as Scene as MapScene).VM.DragStart = {
        x: pointer.x,
        y: pointer.y,
      };
      (scene as Scene as MapScene).VM.CamStart = {
        x: scene.cameras.main.scrollX,
        y: scene.cameras.main.scrollY,
      };
      (scene as Scene as MapScene).VM.SetDragging(false);
    });

    scene.input.on("pointermove", (pointer: Input.Pointer) => {
      if (
        pointer.isDown &&
        !(scene as Scene as MapScene).VM.IsMenu &&
        !(scene as Scene as MapScene).VM.IsBuildMenu
      ) {
        const offsetX = pointer.x - (scene as Scene as MapScene).VM.DragStart.x;
        const offsetY = pointer.y - (scene as Scene as MapScene).VM.DragStart.y;

        // Check if movement exceeds a threshold to consider it as dragging
        if (Math.abs(offsetX) > 5 || Math.abs(offsetY) > 5) {
          (scene as Scene as MapScene).VM.IsHold = false;
          (scene as Scene as MapScene).VM.SetDragging(true);
          scene.cameras.main.scrollX =
            (scene as Scene as MapScene).VM.CamStart.x - offsetX;
          scene.cameras.main.scrollY =
            (scene as Scene as MapScene).VM.CamStart.y - offsetY;
        }
      }
    });

    scene.input.on("pointerup", (pointer: Input.Pointer) => {
      (scene as Scene as MapScene).VM.IsHold = false;
      (scene as Scene as MapScene).VM.SetDragging(false);
    });
  }
}
