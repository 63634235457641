import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_row = _resolveComponent("v-row")!
  const _component_BasicLoginView = _resolveComponent("BasicLoginView")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_AuxLoginView = _resolveComponent("AuxLoginView")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_footer = _resolveComponent("v-footer")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { class: "flex-row justify-center" }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("img", {
            class: "custom-banner",
            src: "/img/icons/android-chrome-512x512.png"
          }, null, -1)
        ])),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_BasicLoginView)
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, { class: "mx-1" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            elevation: "6",
            rounded: "",
            class: "bg-primary",
            size: "large",
            block: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.viewModel.SignUp()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.viewModel.SignUpLabel), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_AuxLoginView)
        ]),
        _: 1
      }),
      _createVNode(_component_v_footer, { app: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { class: "text-right" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "12" }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, _toDisplayString(_ctx.version), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}