import "reflect-metadata";
import IAuth from "@/core/models/Auth/IAuth";
import IBasicLoginViewModel from "./IBasicLoginViewModel";
import { inject, injectable } from "inversify-props";
import ILanguageService from "@/core/services/LanguageService/ILanguageService";
import Session from "@/store/Session";
import { AllTranslationKeys } from "@/core/constants/AllTranslationKeys";
import BaseViewModel from "../BaseViewModel";
import IValidationContex from "@/core/strategies/validator/IValidationContext";
import IEmailValidationStrategy from "@/core/strategies/validator/validators/IEmailValidationStrategy";
import IPropertyError from "@/core/models/PropertyError/IPropertyError";
import IAuthService from "@/core/services/AuthService/IAuthService";

@injectable()
export default class BasicLoginViewModel
  extends BaseViewModel
  implements IBasicLoginViewModel
{
  @inject("Auth")
  private readonly _auth!: IAuth;
  @inject("LanguageService")
  private readonly _langaugeService!: ILanguageService;
  @inject("ValidationContext")
  private readonly _validationContext!: IValidationContex;
  @inject("EmailValidationStrategy")
  private readonly _emailValidationStrat!: IEmailValidationStrategy;
  @inject("AuthService")
  private readonly _authService!: IAuthService;

  public get Title(): string {
    return this._langaugeService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.TITLE_BASIC_LOGIN);
  }

  public get Errors(): Array<IPropertyError> {
    return this._errors;
  }

  public get EmailLabel(): string {
    return this._langaugeService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.EMAIL);
  }

  public get SubmitLabel(): string {
    return this._langaugeService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.SUBMIT);
  }

  public get PassLabel(): string {
    return this._langaugeService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.PASS);
  }

  public get Email(): string {
    return this._auth.Email;
  }

  public set Email(value: string) {
    this._auth.Email = value;
    this.CompileErrors();
  }

  public get Password(): string {
    return this._auth.Password;
  }

  public set Password(value: string) {
    this._auth.Password = value;
  }

  public CompileErrors(): void {
    this._errors = [
      {
        property: "Email",
        errors: this._validationContext
          .SetStrategy(this._emailValidationStrat)
          .Validate(this._auth.Email),
      },
    ].filter(Boolean);
  }

  public async Submit(): Promise<void> {
    await this._authService.LoginWithEmailPass(this._auth);
  }
}
