import { FirebaseOptions } from "firebase/app";

export const FIREBASE_CONFIG: FirebaseOptions = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGID,
  appId: process.env.VUE_APP_FIREBASE_APPID,
};
