import { inject, injectable } from "inversify-props";
import "reflect-metadata";
import ILoadingSceneViewModel from "./ILoadingSceneViewModel";
import IHttpService from "@/core/services/HttpService/IHttpService";
import Phaser from "phaser";
import Session from "@/store/Session";
import IStorageService from "@/core/services/StorageService/IStorageService";

@injectable()
export default class LoadingSceneViewModel implements ILoadingSceneViewModel {
  @inject("HttpService")
  private readonly _httpService!: IHttpService;
  @inject("StorageService")
  private readonly _storageService!: IStorageService;

  private logo: Phaser.GameObjects.Image | undefined;
  private promptText: Phaser.GameObjects.Text | undefined;
  private progBox: Phaser.GameObjects.Graphics | undefined;
  private progBar: Phaser.GameObjects.Graphics | undefined;

  public CreateLogo(scene: Phaser.Scene): void {
    const imgHeight = scene.textures.get("logo").getSourceImage().height;
    this.logo = scene.add.image(
      scene.cameras.main.centerX,
      scene.cameras.main.centerY - imgHeight,
      "logo"
    );
    const maskShape = scene.make.graphics();
    maskShape.fillStyle(0x353839); // White color for the mask
    maskShape.fillRoundedRect(
      this.logo.x - this.logo.width / 2, // X position
      this.logo.y - this.logo.height / 2, // Y position
      this.logo.width, // Width of the rectangle
      this.logo.height, // Height of the rectangle
      30 // Corner radius
    );

    // Create a mask from the graphics object
    const mask = maskShape.createGeometryMask();

    // Apply the mask to the image
    this.logo.setMask(mask);
    this.promptText = scene.add.text(
      scene.cameras.main.centerX - 100,
      scene.cameras.main.centerY - 50,
      "Tap to Continue",
      {
        fontFamily: "Arial",
        fontSize: "25px",
        color: "#FFFFFF",
      }
    );

    scene.input.on("pointerdown", () => {
      this.logo?.destroy();
      this.promptText?.destroy();
      this.logo = undefined;
      this.promptText = undefined;
      scene.scene.start("MapScene");
    });
  }

  public CreateProgressBarAndBox(scene: Phaser.Scene): void {
    this.progBar = scene.add.graphics();
    this.progBox = scene.add.graphics();
    this.progBox?.fillStyle(0x222222, 0.8);
    this.progBox.fillRect(
      20,
      scene.cameras.main.height / 2,
      scene.cameras.main.width - 20,
      50
    );
    scene.load.on("progress", (value: number) => {
      this.progBar?.clear();
      this.progBar?.fillStyle(0xffffff, 1);
      this.progBar?.fillRect(
        22,
        scene.cameras.main.height / 2 - 2,
        value * scene.cameras.main.width - 40,
        48
      );
    });
    scene.load.on("complete", () => {
      this.DestroyProgressBarAndBox(scene);
    });
  }

  public ProcessLoading(scene: Phaser.Scene): void {
    scene.load.rexAwait((successCallBack, failureCallBack) => {
      this._httpService
        .SetInstance("core")
        .SetToken(Session.Token)
        .RequestPost<string, { campaignId: string }>("/getMapLinkById", {
          campaignId: scene.game.config.parent.split("-").at(1),
        })
        .then((d: string) => {
          scene.load.image("mapBackground", d);
          successCallBack();
        })
        .catch(() => {
          failureCallBack();
        });
      scene.load.rexAwait((successCallBack, failureCallBack) => {
        this._storageService
          .GetFileByNameAsync("sprites/structures/fantasy_skirmish/Hideout.png")
          .then((d: string) => {
            scene.load.image("fantasy_skirmish_hideout", d);
            successCallBack();
          })
          .catch(() => {
            failureCallBack();
          });
      });
      scene.load.rexAwait((successCallBack, failureCallBack) => {
        this._storageService
          .GetFileByNameAsync("sprites/structures/fantasy_skirmish/Armory.png")
          .then((d: string) => {
            scene.load.image("fantasy_skirmish_armory", d);
            successCallBack();
          })
          .catch(() => {
            failureCallBack();
          });
      });
      scene.load.rexAwait((successCallBack, failureCallBack) => {
        this._storageService
          .GetFileByNameAsync(
            "sprites/structures/fantasy_skirmish/Arcane_Node.png"
          )
          .then((d: string) => {
            scene.load.image("fantasy_skirmish_arcaneNode", d);
            successCallBack();
          })
          .catch(() => {
            failureCallBack();
          });
      });
      scene.load.rexAwait((successCallBack, failureCallBack) => {
        this._storageService
          .GetFileByNameAsync(
            "sprites/structures/fantasy_skirmish/Healing_Shrine.png"
          )
          .then((d: string) => {
            scene.load.image("fantasy_skirmish_healingShrine", d);
            successCallBack();
          })
          .catch(() => {
            failureCallBack();
          });
      });
      scene.load.rexAwait((successCallBack, failureCallBack) => {
        this._storageService
          .GetFileByNameAsync(
            "sprites/structures/fantasy_skirmish/Training_Ground.png"
          )
          .then((d: string) => {
            scene.load.image("fantasy_skirmish_trainingGround", d);
            successCallBack();
          })
          .catch(() => {
            failureCallBack();
          });
      });
      scene.load.rexAwait((successCallBack, failureCallBack) => {
        this._storageService
          .GetFileByNameAsync(
            "sprites/structures/fantasy_skirmish/Watchtower.png"
          )
          .then((d: string) => {
            scene.load.image("fantasy_skirmish_Watchtower", d);
            successCallBack();
          })
          .catch(() => {
            failureCallBack();
          });
      });
    });
    scene.load.image("logo", "img/icons/android-chrome-192x192.png");
    scene.load.start();
  }

  private DestroyProgressBarAndBox(scene: Phaser.Scene): void {
    this.progBar?.destroy();
    this.progBox?.destroy();
    this.progBar = undefined;
    this.progBox = undefined;
  }
}
