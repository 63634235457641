import { injectable } from "inversify-props";
import "reflect-metadata";
import IAttackButtonCommand from "./IAttackButtonCommand";
import Phaser, { Scene, GameObjects } from "phaser";
import MapScene from "@/core/game/scenes/MapScene";

@injectable()
export default class AttackButtonCommand implements IAttackButtonCommand {
  public Execute<T extends Scene>(
    scene: T,
    parentContainer: GameObjects.Container
  ): GameObjects.Container {
    const attackContainer = scene.add.container(12, 60);
    const shadow = scene.add.graphics();
    shadow.fillStyle(0x000000, 0.5);
    shadow.fillRoundedRect(5, 5, 103, 45, 10);
    const attackRect = scene.add.graphics();
    attackRect.fillStyle(0xa5cbf4, 1);
    attackRect.fillRoundedRect(0, 0, 103, 45, 10);
    attackRect.setInteractive(
      new Phaser.Geom.Rectangle(0, 0, 103, 45),
      Phaser.Geom.Rectangle.Contains
    );
    attackRect.on("pointerup", async () => {
      await (scene as Scene as MapScene).VM.HandleAttackClick(
        parentContainer.getData("hex")._id
      );
      (scene as Scene as MapScene).VM.IsHexMenu = false;
      parentContainer.destroy();
    });
    const attackText = scene.add.text(5, 10, "ATTACK", {
      fontSize: "24px",
      fontFamily: "Arial",
      color: "#000000",
    });
    attackContainer.add(shadow);
    attackContainer.add(attackRect);
    attackContainer.add(attackText);
    attackContainer.setDepth(100);
    parentContainer.add(attackContainer);
    return attackContainer;
  }
}
