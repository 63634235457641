import { container } from "inversify-props";
import IBasicLoginViewModel from "./core/viewModels/BasicLoginViewModel/IBasicLoginViewModel";
import BasicLoginViewModel from "./core/viewModels/BasicLoginViewModel/BasicLoginViewModel";
import ILanguageService from "./core/services/LanguageService/ILanguageService";
import LanguageService from "./core/services/LanguageService/LanguageService";
import IAuth from "./core/models/Auth/IAuth";
import Auth from "./core/models/Auth/Auth";
import IAuthService from "./core/services/AuthService/IAuthService";
import AuthService from "./core/services/AuthService/AuthService";
import IHttpService from "./core/services/HttpService/IHttpService";
import HttpService from "./core/services/HttpService/HttpService";
import IValidationContex from "./core/strategies/validator/IValidationContext";
import ValidationContext from "./core/strategies/validator/ValidationContext";
import IEmailValidationStrategy from "./core/strategies/validator/validators/IEmailValidationStrategy";
import EmailValidationStrategy from "./core/strategies/validator/validators/EmailValidationStrategy";
import ILoginViewViewModel from "./core/viewModels/LoginViewViewModel/ILoginViewViewModel";
import LoginViewViewModel from "./core/viewModels/LoginViewViewModel/LoginViewViewModel";
import ICampaignService from "./core/services/CampaignService/ICampaignService";
import CampaignService from "./core/services/CampaignService/CampaignService";
import ICampaignListViewModel from "./core/viewModels/CampaignListViewModel/ICampaignListViewModel";
import CampaignListViewModel from "./core/viewModels/CampaignListViewModel/CampaignListViewModel";
import IAlertDataFactory from "./core/factories/AlertDataFactory/IAlertDataFactory";
import AlertDataFactory from "./core/factories/AlertDataFactory/AlertDataFactory";
import IMessageService from "./core/services/MessageService/IMessageService";
import MessageService from "./core/services/MessageService/MessageService";
import IUserService from "./core/services/UserService/IUserService";
import UserService from "./core/services/UserService/UserService";
import ICampaignViewViewModel from "./core/viewModels/CampaignViewViewModel/ICampaignViewViewModel";
import CampaignViewViewModel from "./core/viewModels/CampaignViewViewModel/CampaignViewViewModel";
import ILoadingSceneViewModel from "./core/viewModels/LoadingSceneViewModel/ILoadingSceneViewModel";
import LoadingSceneViewModel from "./core/viewModels/LoadingSceneViewModel/LoadingSceneViewModel";
import LoadingScene from "./core/game/scenes/LoadingScene";
import MapScene from "./core/game/scenes/MapScene";
import IMapSceneViewModel from "./core/viewModels/MapSceneViewModel/IMapSceneViewModel";
import MapSceneViewModel from "./core/viewModels/MapSceneViewModel/MapSceneViewModel";
import ISceneEventCommandHandler from "./core/commands/SceneEventCommandHandler/ISceneEventCommandHandler";
import SceneEventCommandHandler from "./core/commands/SceneEventCommandHandler/SceneEventCommandHandler";
import IDraggableCameraEventCommand from "./core/commands/SceneEventCommands/IDraggableCameraEventCommand";
import DraggableCameraEventCommand from "./core/commands/SceneEventCommands/DraggableCameraEventCommand";
import IHexGridBuilder from "./core/builders/HexGridBuilder/IHexGridBuilder";
import HexGridBuilder from "./core/builders/HexGridBuilder/HexGridBuilder";
import IPinchEventCommand from "./core/commands/SceneEventCommands/IPinchEventCommand";
import PinchEventCommand from "./core/commands/SceneEventCommands/PinchEventCommand";
import ITouchAndHoldEventCommand from "./core/commands/SceneEventCommands/ITouchAndHoldEventCommand";
import TouchAndHoldEventCommand from "./core/commands/SceneEventCommands/TouchAndHoldEventCommand";
import IMenuFactory from "./core/factories/MenuFactory/IMenuFactory";
import MenuFactory from "./core/factories/MenuFactory/MenuFactory";
import IButtonCommandHandler from "./core/commands/ButtonCommandHandler/IButtonCommandHandler";
import ButtonCommandHandler from "./core/commands/ButtonCommandHandler/ButtonCommandHandler";
import IBackToMainButtonCommand from "./core/commands/ButtonCommands/IBackToMainButtonCommand";
import BackToMainButtonCommand from "./core/commands/ButtonCommands/BackToMainButtonCommand";
import IShowGridButtonCommand from "./core/commands/ButtonCommands/IShowGridButtonCommand";
import ShowGridButtonCommand from "./core/commands/ButtonCommands/ShowGridButtonCommand";
import IShowColorsButtonCommand from "./core/commands/ButtonCommands/IShowColorsButtonCommand";
import ShowColorsButtonCommand from "./core/commands/ButtonCommands/ShowColorsButtonCommand";
import IExitButtonCommand from "./core/commands/ButtonCommands/IExitButtonCommand";
import ExitButtonCommand from "./core/commands/ButtonCommands/ExitButtonCommand";
import IAttackButtonCommand from "./core/commands/ButtonCommands/IAttackButtonCommand";
import AttackButtonCommand from "./core/commands/ButtonCommands/AttackButtonCommand";
import IBuildButtonCommand from "./core/commands/ButtonCommands/IBuildButtonCommand";
import BuildButtonCommand from "./core/commands/ButtonCommands/BuildButtonCommand";
import IHistoryButtonCommand from "./core/commands/ButtonCommands/IHistoryButtonCommand";
import HistoryButtonCommand from "./core/commands/ButtonCommands/HistoryButtonCommand";
import IExitBuildButtonCommand from "./core/commands/ButtonCommands/IExitBuildButtonCommand";
import ExitBuildButtonCommand from "./core/commands/ButtonCommands/ExitBuildButtonCommand";
import IBuildHideoutButtonCommand from "./core/commands/ButtonCommands/IBuildHideoutButtonCommand";
import BuildHideoutButtonCommand from "./core/commands/ButtonCommands/BuildHideoutButtonCommand";
import BuildArcaneNodeButtonCommand from "./core/commands/ButtonCommands/BuildArcaneNodeButtonCommand";
import BuildArmoryButtonCommand from "./core/commands/ButtonCommands/BuildArmoryButtonCommand";
import BuildHealingShrineButtonCommand from "./core/commands/ButtonCommands/BuildHealingShrineButtonCommand";
import BuildTrainingGroundButtonCommand from "./core/commands/ButtonCommands/BuildTrainingGroundButtonCommand";
import BuildWatchTowerButtonCommand from "./core/commands/ButtonCommands/BuildWatchTowerButtonCommand";
import IBuildArcaneNodeButtonCommand from "./core/commands/ButtonCommands/IBuildArcaneNodeButtonCommand";
import IBuildArmoryButtonCommand from "./core/commands/ButtonCommands/IBuildArmoryButtonCommand";
import IBuildHealingShrineButtonCommand from "./core/commands/ButtonCommands/IBuildHealingShrineButtonCommand";
import IBuildTrainingGroundButtonCommand from "./core/commands/ButtonCommands/IBuildTrainingGroundButtonCommand";
import IBuildWatchTowerButtonCommand from "./core/commands/ButtonCommands/IBuildWatchTowerButtonCommand";
import IFirebaseWrapper from "./core/wrappers/FirebaseWrapper/IFirebaseWrapper";
import FirebaseWrapper from "./core/wrappers/FirebaseWrapper/FirebaseWrapper";
import IStorageService from "./core/services/StorageService/IStorageService";
import StorageService from "./core/services/StorageService/StorageService";
import IProfileViewModel from "./core/viewModels/ProfileViewModel/IProfileViewModel";
import ProfileViewModel from "./core/viewModels/ProfileViewModel/ProfileViewModel";
import IHexHistoryViewModel from "./core/viewModels/HexHistoryViewModel/IHexHistoryViewModel";
import HexHistoryViewModel from "./core/viewModels/HexHistoryViewModel/HexHistoryViewModel";
import IHexEventCardViewModel from "./core/viewModels/HexEventCardViewModel/IHexEventCardViewModel";
import HexEventCardViewModel from "./core/viewModels/HexEventCardViewModel/HexEventCardViewModel";

export default function buildDependencyContainer(): void {
  container.addRequest(LoadingScene, "LoadingScene");
  container.addRequest(MapScene, "MapScene");

  //#region  View Models
  container.addRequest<IProfileViewModel>(ProfileViewModel, "ProfileViewModel");
  container.addRequest<IBasicLoginViewModel>(
    BasicLoginViewModel,
    "BasicViewModel"
  );
  container.addRequest<ILoginViewViewModel>(
    LoginViewViewModel,
    "LoginViewViewModel"
  );
  container.addRequest<ICampaignListViewModel>(
    CampaignListViewModel,
    "CampaignListViewModel"
  );
  container.addRequest<ICampaignViewViewModel>(
    CampaignViewViewModel,
    "CampaignViewViewModel"
  );
  container.addRequest<ILoadingSceneViewModel>(
    LoadingSceneViewModel,
    "LoadingSceneViewModel"
  );
  container.addRequest<IMapSceneViewModel>(
    MapSceneViewModel,
    "MapSceneViewModel"
  );
  container.addRequest<IHexHistoryViewModel>(
    HexHistoryViewModel,
    "HexHistoryViewModel"
  );
  container.addRequest<IHexEventCardViewModel>(
    HexEventCardViewModel,
    "HexEventCardViewModel"
  );
  //#endregion

  //#region Services
  container.addTransient<ILanguageService>(LanguageService, "LanguageService");
  container.addTransient<IAuthService>(AuthService, "AuthService");
  container.addSingleton<IHttpService>(HttpService, "HttpService");
  container.addTransient<IMessageService>(MessageService, "MessageService");
  container.addTransient<ICampaignService>(CampaignService, "CampaignService");
  container.addTransient<IUserService>(UserService, "UserService");
  container.addTransient<IStorageService>(StorageService, "StorageService");
  //#endregion

  //#region Core
  container.addRequest<IAuth>(Auth, "Auth");
  container.addTransient<IValidationContex>(
    ValidationContext,
    "ValidationContext"
  );
  container.addTransient<IEmailValidationStrategy>(
    EmailValidationStrategy,
    "EmailValidationStrategy"
  );
  container.addTransient<IAlertDataFactory>(
    AlertDataFactory,
    "AlertDataFactory"
  );
  container.addTransient<ISceneEventCommandHandler>(
    SceneEventCommandHandler,
    "SceneEventCommandHandler"
  );
  container.addTransient<IDraggableCameraEventCommand>(
    DraggableCameraEventCommand,
    "DraggableCameraEventCommand"
  );
  container.addTransient<IPinchEventCommand>(
    PinchEventCommand,
    "PinchEventCommand"
  );
  container.addTransient<IHexGridBuilder>(HexGridBuilder, "HexGridBuilder");
  container.addTransient<ITouchAndHoldEventCommand>(
    TouchAndHoldEventCommand,
    "TouchAndHoldEventCommand"
  );
  container.addTransient<IMenuFactory>(MenuFactory, "MenuFactory");
  container.addTransient<IButtonCommandHandler>(
    ButtonCommandHandler,
    "ButtonCommandHandler"
  );
  container.addTransient<IBackToMainButtonCommand>(
    BackToMainButtonCommand,
    "BackToMainButtonCommand"
  );
  container.addTransient<IShowGridButtonCommand>(
    ShowGridButtonCommand,
    "ShowGridButtonCommand"
  );
  container.addTransient<IShowColorsButtonCommand>(
    ShowColorsButtonCommand,
    "ShowColorsButtonCommand"
  );
  container.addTransient<IExitButtonCommand>(
    ExitButtonCommand,
    "ExitButtonCommand"
  );
  container.addTransient<IAttackButtonCommand>(
    AttackButtonCommand,
    "AttackButtonCommand"
  );
  container.addTransient<IBuildButtonCommand>(
    BuildButtonCommand,
    "BuildButtonCommand"
  );
  container.addTransient<IHistoryButtonCommand>(
    HistoryButtonCommand,
    "HistoryButtonCommand"
  );
  container.addTransient<IExitBuildButtonCommand>(
    ExitBuildButtonCommand,
    "ExitBuildButtonCommand"
  );
  container.addTransient<IBuildHideoutButtonCommand>(
    BuildHideoutButtonCommand,
    "BuildHideoutButtonCommand"
  );
  container.addTransient<IBuildArmoryButtonCommand>(
    BuildArmoryButtonCommand,
    "BuildArmoryButtonCommand"
  );
  container.addTransient<IBuildArcaneNodeButtonCommand>(
    BuildArcaneNodeButtonCommand,
    "BuildArcaneNodeButtonCommand"
  );
  container.addTransient<IBuildWatchTowerButtonCommand>(
    BuildWatchTowerButtonCommand,
    "BuildWatchTowerButtonCommand"
  );
  container.addTransient<IBuildTrainingGroundButtonCommand>(
    BuildTrainingGroundButtonCommand,
    "BuildTrainingGroundButtonCommand"
  );
  container.addTransient<IBuildHealingShrineButtonCommand>(
    BuildHealingShrineButtonCommand,
    "BuildHealingShrineButtonCommand"
  );
  container.addSingleton<IFirebaseWrapper>(FirebaseWrapper, "FirebaseWrapper");
  //#endregion
}
