import { inject, injectable } from "inversify-props";
import "reflect-metadata";
import IStorageService from "./IStorageService";
import IFirebaseWrapper from "@/core/wrappers/FirebaseWrapper/IFirebaseWrapper";
import { ref, uploadBytes } from "firebase/storage";

@injectable()
export default class StorageService implements IStorageService {
  private readonly _firebase: IFirebaseWrapper;

  public constructor(@inject("FirebaseWrapper") firebase: IFirebaseWrapper) {
    this._firebase = firebase;
  }

  public async GetFileByNameAsync(filePath: string): Promise<string> {
    const storage = this._firebase.GetStorage();
    const fileRef = ref(storage, filePath);
    try {
      return await this._firebase.GetDownloadURL(fileRef);
    } catch (e) {
      console.warn(e);
      return "";
    }
  }

  public async AddFileAsync(
    filePath: string,
    fileData: Blob | Uint8Array | ArrayBuffer
  ): Promise<string> {
    const mime = this.GetMime(filePath);
    const storage = this._firebase.GetStorage();
    const fileRef = ref(storage, filePath);
    const res = await uploadBytes(fileRef, fileData, this.GetMime(filePath));
    return await this._firebase.GetDownloadURL(res.ref);
  }

  private GetMime(filePath: string): { contentType: string } {
    const mediaType: { [key: string]: string } = {
      jpg: "image/jpeg",
      jpeg: "image/jpeg",
      png: "image/png",
      gif: "image/gif",
    };
    const ext = filePath.split(".").at(1);
    return { contentType: mediaType[ext as string] };
  }
}
