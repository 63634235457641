import "reflect-metadata";
import { inject, injectable } from "inversify-props";
import ILoginViewViewModel from "./ILoginViewViewModel";
import router from "@/router";
import ILanguageService from "@/core/services/LanguageService/ILanguageService";
import Session from "@/store/Session";
import { AllTranslationKeys } from "@/core/constants/AllTranslationKeys";

@injectable()
export default class LoginViewViewModel implements ILoginViewViewModel {
  @inject("LanguageService")
  private readonly _languageService!: ILanguageService;

  public SignUp(): void {
    router.push({ path: "/sign-up" });
  }

  public get SignUpLabel(): string {
    return this._languageService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.SIGN_UP);
  }
}
