<template>
  <v-container>
    <v-row class="flex-row justify-center">
      <v-col class="d-flex justify-center">
        <v-avatar size="130" :image="viewModel.PictureURI"></v-avatar>
      </v-col>
      <v-col>
        <v-btn
          rounded
          block
          elevation="3"
          size="large"
          class="bg-primary"
          @click="() => viewModel.ToggleDialog()"
          >{{ viewModel.ChangePictureLabel }}</v-btn
        >
      </v-col>
    </v-row>
    <v-dialog persistent v-model="viewModel.ShowDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">{{
          viewModel.ChangePassLabel
        }}</v-card-title>
        <v-card-text>
          <v-file-input
            label="Upload New Picture"
            accept="image/*"
            @update:model-value="(e: File) => viewModel.File = e"
            :model-value="viewModel.File"
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="bg-accent" @click="viewModel.ToggleDialog()"
            >Cancel</v-btn
          >
          <v-btn class="bg-primary" @click="viewModel.UpdatePicture()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12">
        <v-text-field
          rounded
          :label="viewModel.UsernameLabel"
          variant="outlined"
          :model-value="viewModel.Username"
          @update:model-value="(e: string) => viewModel.Username = e"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-btn
          rounded
          block
          elevation="3"
          size="large"
          class="bg-primary"
          @click="() => viewModel.UpdateUserName()"
          >{{ viewModel.ChangeUserNameLabel }}</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          rounded
          :label="viewModel.EmailLabel"
          variant="outlined"
          :model-value="viewModel.Email"
          @update:model-value="(e: string) => viewModel.Email = e"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-btn
          rounded
          block
          elevation="3"
          size="large"
          class="bg-primary"
          @click="() => viewModel.UpdateEmail()"
          >{{ viewModel.ChangeEmailLabel }}</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          rounded
          :label="viewModel.PassLabel"
          variant="outlined"
          :model-value="viewModel.Pass"
          @update:model-value="(e: string) => viewModel.Pass = e"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-btn
          rounded
          block
          elevation="3"
          size="large"
          class="bg-primary"
          @click="() => viewModel.UpdatePassword()"
          >{{ viewModel.ChangePassLabel }}</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import BaseViewModel from "@/core/viewModels/BaseViewModel";
import IProfileViewModel from "@/core/viewModels/ProfileViewModel/IProfileViewModel";
import { Vue } from "vue-class-component";
import { InjectReactive } from "vue-property-decorator";

export default class ProfileView extends Vue {
  @InjectReactive("ProfileViewModel")
  private readonly viewModel!: IProfileViewModel;

  public async mounted() {
    this.viewModel.MountUser();
  }
}
</script>

<style scoped></style>
