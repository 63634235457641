import { injectable } from "inversify-props";
import "reflect-metadata";
import IBackToButtonCommand from "./IBackToMainButtonCommand";
import { Scene, GameObjects } from "phaser";
import MapScene from "@/core/game/scenes/MapScene";

@injectable()
export default class BackToMainButtonCommand implements IBackToButtonCommand {
  public Execute<T extends Scene>(
    scene: T,
    parentContainer: GameObjects.Container
  ): GameObjects.Container {
    const backToMainContainer = scene.add.container(
      parentContainer.x + 10,
      parentContainer.y + 100
    );
    scene.events.on("postupdate", () => {
      backToMainContainer.setPosition(
        scene.cameras.main.scrollX + parentContainer.x + 25,
        scene.cameras.main.scrollY + parentContainer.y + 100
      );
    });
    const backRect = scene.add.rectangle(0, 0, 320, 50, 0xececec, 0.75);
    backRect.setOrigin(0, 0);
    backRect.setInteractive();
    backRect.on("pointerup", () => {
      (scene as Scene as MapScene).VM.IsMenu = false;
      parentContainer.setVisible(false);
    });
    backToMainContainer.add(backRect);
    // TODO: Change this to VM.BACKTOHEXBUTTONLABEL
    const backText = scene.add.text(55, 5, "Back to Hex Grid", {
      fontSize: "30px",
      fontFamily: "Arial",
      color: "#000000",
    });
    backToMainContainer.add(backText);
    parentContainer.add(backToMainContainer);
    return backToMainContainer;
  }
}
